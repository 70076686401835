import React from 'react';
import { useState, useEffect } from 'react';


import {URLS} from './URLS';

import {BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker} from './Inputs'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Card } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#ffffff',
        height: "100%"
    },
    orderableTH: {
        background: '#555555',
        color: '#ffffff',
      },
  }));

var tableToExcel = (function () {
    var uri = 'data:application/vnd.ms-excel;base64,'
        ,
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
        , base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        }
        , format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            })
        }
        , downloadURI = function (uri, name) {
            var link = document.createElement("a");
            link.download = name;
            link.href = uri;
            link.click();
        }

    return function (table, name, fileName) {
        if (!table.nodeType) table = document.getElementById(table)
        var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
        var resuri = uri + base64(format(template, ctx))
        downloadURI(resuri, fileName);
    }
})();

function ReportTab({lists, user, initstate, storeReportState}){

    const [resulthtml, setResultHTML] = useState(null)

    const splits = [
        {id: 1, name: 'User'}, 
        {id: 2, name: 'Project'}, 
        {id: 3, name: 'Task'}, 
        {id: 4, name: 'Date'}, 
        {id: 5, name: 'Billing Report'}, 
        {id: 8, name: 'Billing Report SO'}, 
        {id: 6, name: 'Hours'}, 
        {id: 7, name: 'QA report'},
        {id: 9, name: 'SALARY HOURS'},
        {id: 10, name: "USER BY TASK"}, 
        {id: 11, name: "User By Hour"},
        {id: 12, name: "KPI Scores"},
        {id: 13, name: "TOP CLIENT"},
    ];

    const classes = useStyles();



   
    const checkError = () => {
        return '';
    }

    function handleGenerate() {
        console.log(initstate);
        var days = (initstate.to - initstate.from) / (1000*60*60*24);
        console.log({pn: initstate.project_number, maconomy: initstate.maconomy});
        if(days > 190 && initstate.project_number == "" && initstate.maconomy == ""){
            alert("Too long time range. Try to limit it to 6 months at most.");
            return null;
        }

        var pass_data = {
            resp_type: "LIST",
            from_date: initstate.from.getFullYear() + '-' + ("0" + (initstate.from.getMonth() + 1)).slice(-2) + '-' + ('0' + (initstate.from.getDate())).slice(-2),
            to_date: initstate.to.getFullYear() + '-' + ("0" + (initstate.to.getMonth() + 1)).slice(-2) + '-' + ('0' + (initstate.to.getDate())).slice(-2),
            from_time: initstate.from.getHours()+":"+initstate.from.getMinutes(),
            to_time: initstate.to.getHours()+":"+initstate.to.getMinutes(),
            task_type: initstate.task_type,
            user: initstate.user,
            software: initstate.software,
            client: initstate.client,
            top_client: initstate.top_client,
            project_number: initstate.project_number,
            study_type: initstate.study_type,
            region: initstate.region,
            role: initstate.role,
            maconomy: initstate.maconomy,
            orion: initstate.orion,
            split_by: initstate.by,
            status: initstate.status,
            cross: initstate.split_by,
            num_hours: initstate.num_hours,
            kpi_by_project: initstate.kpi_by_project,
            general_type: initstate.project_type,
        }

         
        fetch(URLS.aj_filtered_report, {method: "POST", body: JSON.stringify(pass_data)})
            .then(response => response.text()).then(result => {
                setResultHTML({__html: result});
            })
            .catch(error => console.error(error));
    }

    function setInput(val, json_name){
        var newinitstate = {...initstate};
        if (!json_name.includes('.')) {
            newinitstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newinitstate[res[0]][res[1]] = val;
        }
 
        storeReportState(newinitstate);

 
    }

    function handleSelectChange(val, json_name, list){
        console.log({val: val, json_name: json_name})
        var elem = list.filter(e => e.id === val)[0];
        var newinitstate = {...initstate};
        if(!elem){
            newinitstate[json_name] = {
                'id': -1, 
                'name': 'undef'
            }
        }else{
            var selectedText = elem.name;
            console.log(selectedText);
            
            if (!json_name.includes('.')) {
                newinitstate[json_name].id = val;
                newinitstate[json_name].name = selectedText;
            } else {
                var res = json_name.split('.');
                newinitstate[res[0]][res[1]].id = val;
                newinitstate[res[0]][res[1]].name = selectedText;
            }
        }
        
        storeReportState(newinitstate);
    }

    function handleCheckboxChange(val, json_name){
        var newinitstate = {...initstate};
        if (!json_name.includes('.')) {
            newinitstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newinitstate[res[0]][res[1]] = val;
        }
        storeReportState(newinitstate);
    }

    function handleMultiChange(val, json_name){
        var newinitstate = {...initstate};
        if (!json_name.includes('.')) {
            newinitstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newinitstate[res[0]][res[1]] = val;
        }
        storeReportState(newinitstate);
    }

    function handleNewDateTime(val, json_name){
        var newinitstate = {...initstate};
        console.log({val: val, json_name: json_name});
 
        if (!json_name.includes('.')) {
            newinitstate[json_name] = new Date(val);
        } else {
            var res = json_name.split('.');
            newinitstate[res[0]][res[1]] = new Date(val);
        }
 
        storeReportState(newinitstate);  
    }


    return (
        <Grid container>
            <Grid item sm={12} md={12} lg={6}>
                <Card>
                    <CardContent>
                        <Grid container spacing={6}>
                            <Grid item sm={12}>
                                <Typography variant="h5">Base Filters</Typography>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <BuildDateTimePicker init_value={initstate['from']} label="From" json_name='from' onChange={handleNewDateTime} />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <BuildDateTimePicker init_value={initstate['to']} label="To" json_name='to' onChange={handleNewDateTime} />
                            </Grid>

                            <Grid item sm={12} md={6}>
                                <BuildSelect2 json_name='by' label='Export Type' list={splits} init_value={initstate['by']} getError={checkError} onChange={handleSelectChange} />
                            </Grid>
                            {initstate['by'].id !== 12 && 
                            <>
                            <Grid item sm={12} md={6}>
                                <BuildMultiSelect json_name="split_by" label="Split By" init_value={initstate['split_by']} getError={checkError} list={splits} onChange={handleMultiChange} />
                            </Grid>
                            
                            
                            <Grid item sm={12} md={6}>
                            <BuildText json_name='project_number' label="Project Number(SN)" getError={checkError} onChange={setInput} init_value={initstate.project_number} />
                            </Grid>
                            <Grid item sm={12} md={6}>
                            <BuildText json_name='maconomy' label="Maconomy" getError={checkError} onChange={setInput} init_value={initstate.maconomy} />
                            </Grid>
                            </>
                            }

                        </Grid>   
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={6}>
                <Card>
                    <CardContent>
                        <Grid container spacing={6}>
                            <Grid item sm={12}>
                                <Typography variant="h5">Extra filters</Typography>    
                            </Grid>
                            {initstate['by'].id !== 12 && 
                            <Grid item sm={12} md={4}>
                                <BuildSelect2 json_name='task_type' label='Task Type' list={lists.task_types} init_value={initstate['task_type']} getError={checkError} onChange={handleSelectChange} />
                            </Grid>
                            }
                            <Grid item sm={12} md={4}>
                                <BuildSelect2 json_name='user' label='User' list={lists.users} init_value={initstate['user']} getError={checkError} onChange={handleSelectChange} />
                            </Grid>
                            {initstate['by'].id !== 12 && 
                            <>
                                <Grid item sm={12} md={4}>
                                    <BuildSelect2 json_name='client' label='Client' list={lists.clients} init_value={initstate['client']} getError={checkError} onChange={handleSelectChange} />
                                </Grid>
                            
                                <Grid item sm={12} md={4}>
                                    <BuildSelect2 json_name='top_client' label='TOP Client' list={lists.top_clients} init_value={initstate['top_client']} getError={checkError} onChange={handleSelectChange} />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <BuildSelect2 json_name='study_type' label='Study Type' list={lists.types} init_value={initstate['study_type']} getError={checkError} onChange={handleSelectChange} />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <BuildSelect2 json_name='region' label='Region' list={lists.regions} init_value={initstate['region']} getError={checkError} onChange={handleSelectChange} />
                                </Grid>
                            </>
                            }
                            <Grid item sm={12} md={4}>
                                <BuildSelect2 json_name='region' label='Region' list={lists.regions} init_value={initstate['region']} getError={checkError} onChange={handleSelectChange} />
                            </Grid>
                            
                            <Grid item sm={12} md={4}>
                                <BuildSelect2 json_name='role' label='Role' list={lists.roles} init_value={initstate['role']} getError={checkError} onChange={handleSelectChange} />
                            </Grid>
                            {initstate['by'].id !== 12 && 
                                <>
                                <Grid item sm={12} md={4}>
                                <BuildSelect2 json_name='status' label='Status' list={lists.statuses} init_value={initstate['status']} getError={checkError} onChange={handleSelectChange} />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                <BuildCheckbox json_name="orion" label="Orion" init_value={initstate.orion} getError={checkError} onChange={handleCheckboxChange} />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                <BuildText json_name='num_hours' label="Min Hours" getError={checkError} onChange={setInput} init_value={initstate['num_hours']} />
                                </Grid>
                                </>
                            }
                            {initstate['by'].id == 12 &&
                            <>
                            <Grid item sm={12} md={4}>
                                <BuildCheckbox json_name="kpi_by_project" label="By Project" init_value={false} getError={checkError} onChange={handleCheckboxChange} />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <BuildMultiSelect json_name="project_type" label="Project Type" init_value={initstate.project_type} getError={checkError} list={lists.general_types} onChange={handleMultiChange} />
                            </Grid>
                            </>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12}>
                <Card>
                    <CardActions>
                        <Button onClick={handleGenerate} className={classes.button_general}>SUBMIT</Button>
                        {resulthtml !== null &&
                            <Button id="to_excel" onClick={() => tableToExcel('resultTable', 'EXPORT', 'general_report_results.xls')} className={classes.button_general}>To Excel</Button>
                        }
                    </CardActions>
                </Card>
            </Grid>

            <Grid item xl={12}>
                <div dangerouslySetInnerHTML={resulthtml} className="center"></div>
            </Grid>
            
            
        </Grid>
    );
}


export {ReportTab}