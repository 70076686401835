import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';

import { URLS } from './URLS';
import { FormGroup, Container, FormHelperText } from '@material-ui/core';
import { AppNavBar } from './AppNavBar';
import DateFnsUtils from '@date-io/date-fns';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100% !important'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#eee',
        height: "100%"
    },
}));

function BuildMultiSelect({json_name, label, init_value, getError, list, onChange, singleSelect=false, active=true, etasindex=null}){
    const [selected, setSelected] = useState([]);
    const classes = useStyles();
    useEffect(()=>{
        if(init_value){     
            setSelected(init_value);
        }
    },[init_value])


    function onSelect(selectedList, selectedItem) {
        //alert(selectedItem);
        setSelected(selectedList);
        onChange(selectedList, json_name, etasindex);
        //handleMultiChange(selectedList, json_name);
    }
     
    function onRemove(selectedList, removedItem) {
        setSelected(selectedList, json_name);
        //handleMultiChange(selectedList, json_name);
        onChange(selectedList, json_name, etasindex);
    }


    return(
        <React.Fragment>
            {label}
            <Multiselect
            options={list}
            selectedValues={selected}
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="name"
            autoComplete="new-password"
            disabled={!active}
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
        </React.Fragment>
        
    )
}




function ClientViewModifier({ user, top_clients, lists }) {
    const classes = useStyles();



    var preselected_type = {
        id: 999999,
        name: 'undefined',
    }
    const [top_client, setTopClient] = useState(999999);
    const [region, setRegion] = useState(999999);

    const etatypes = [
        {id: 1, name: "ETA Scripting"},
        {id: 2, name: "ETA Tabs"},
        {id: 3, name: "ETA Charts"},
        {id: 4, name: "ETA CJ"},
        {id: 5, name: "ETA DP"},
        {id: 6, name: "Project ETA"},
        {id: 7, name: "Project FW"},
        {id: 8, name: "Project PM"},
        {id: 9, name: "Project Overlay"},
        {id: 10, name: "Project FW Setup"},
        {id: 11, name: "Revised ETA"},
    ]
    const [fields, setFields] = useState([]);

    const baseEta = {
        id: {id: 999999, name: 'undefined'},
        etas: [],
        tasks: [],
        percentage: 100,
        boost_coef: 1,
        at: ['somebody@somewhere.com'],
        to_PM: false,
    }
    const baseSpecificEta = {
        clid: {id: 999999, name: 'undefined'},
        id: {id: 999999, name: 'undefined'},
        etas: [],
        tasks: [],
        percentage: 100,
        boost_coef: 1,
        at: ['somebody@somewhere.com'],
        to_PM: false,
    }

    const [etas, setEtas] = useState([]);
    const [specific_etas, setSpecificEtas] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [allFields, setAllFields] = useState([])

    const [taskTypes, setTaskTypes] = useState([]);
    const [newEtaName,setNewEtaName] = useState("")

    function setToBasic() {
        setFields([]);
        setEtas([]);
        setSpecificEtas([]);
    }

    function postTopClientChange() {
        fetch(URLS.aj_get_project_schema, {
            method: "POST",
        }).then(res=>res.json()).then(response => {

            console.log({project_schema: response});
            setAllFields(response.fields);
        })

        fetch(URLS.aj_get_top_client, {
            method: "POST",
            body: JSON.stringify({
                top_client_id: top_client,
                region_id: region,
            })
        }).then(res => res.json()).then((result) => {
            
            if (Object.keys(result.view_fields).length !== 0) {
                var newfields = result.view_fields;
                var newetas = result.etas;
                var newspecetas = result.specific_etas;

                console.log(newfields)
                setFields(newfields);
                setEtas(newetas);
                setSpecificEtas(newspecetas);
            }else{
                setToBasic()
            }
        },

        (error) => {
            console.log(error);
        })
    }
    function handleSetRegion(val) {
        setRegion(val)
    }
    function handleSetClient(val) {
        setTopClient(val)
    }
    function setInput(e, json_name) {
        var newfields = fields;
        if (!json_name.includes('.')) {
            newfields[json_name] = e.target.value;
        } else {
            var res = json_name.split('.');
            newfields[res[0]][res[1]] = e.target.value;
        }

        setFields(newfields);

        console.log(fields);
    }

    function handleTopClientChange(e, list) {
        var selectedValue = e.target.value;
        var elem = list.filter(e => e.id === selectedValue)[0]
        var selectedText = elem.name;

        var new_top_client = {...top_client};
        

        new_top_client.id = e.target.value;
        new_top_client.name = selectedText;

        setTopClient(new_top_client);
        
    }

    function getInitList(){
        fetch(URLS.aj_get_all_tasks,{
            method: "POST",
        }).then(res => res.json()).then((response) => {
            console.log({task_types: response})
            setTaskTypes(response.task_types)
        })
    }

    useEffect(()=>{
        if(top_client!==999999 && region!==999999){
            postTopClientChange();
            getInitList();
        }
    },[top_client,region])

    function handleETACheckboxChange(val, json_name, indeta) {
        var newetas = [...etas];

        newetas[indeta][json_name] = val;
        setEtas(newetas);
           

    }
    
    function handleCheckboxChange(val, json_name) {
        var newfields = fields;
        if (!json_name.includes('.')) {
            newfields[json_name] = val;
        } else {
            try {
                var res = json_name.split('.');
                newfields[res[0]][res[1]] = val;
            } catch (error) {
                var res = json_name.split('.');
                newfields[res[0]] = {}
                newfields[res[0]][res[1]] = val;
            }

        }

        setFields(newfields);

    }

    function checkError() {
        return "";
    }

    function handleSubmit(e) {

        console.log({
            top_client_id: top_client,
            region_id: region,
            view_fields: fields,
            etas: etas,
            specific_etas: specific_etas,
        })

        fetch(URLS.aj_set_top_client_fields, {
            method: "POST",
            body: JSON.stringify({
                top_client_id: top_client,
                region_id: region,
                view_fields: fields,
                etas: etas,
                specific_etas: specific_etas,
            })
        }).then(res => res.json()).then((result) => {
            console.log(result);
            setShowSuccess(true);
            setTimeout(function(){
                setShowSuccess(false);
            },2000)
        },

            (error) => {
                console.log(error);
            })

    }

    function BuildClientSelect({ json_name, label, list, init_value, getError }) {
        const [val, setVal] = useState(preselected_type)
        const [error, setError] = useState('');

        useEffect(() => {
            setError(getError(json_name));
            setVal(init_value);
        }, [])

        function handleChange(e) {
            console.log(e);
            setVal(e.target.value);
            handleTopClientChange(e, list);
            setError(getError(json_name));
        }
        return (
            <FormControl className={classes.formControl} error={error != ''}>
                <InputLabel id={json_name}>{label}</InputLabel>
                <Select
                    labelId={json_name}
                    id="demo-simple-select"
                    value={val}
                    onChange={handleChange}
                >
                    {list && list.map((a, ind, arr) => {
                        return (
                            <MenuItem value={a.id} name={a.name}>{a.name}</MenuItem>
                        )
                    })}

                </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        )
    }

    function BuildETASelect({json_name, label, list, init_value, etas_index, getError, onChange=null, active=true}){
        const [val, setVal] = useState(preselected_type)
        const [error, setError] = useState('');
        const classes = useStyles();
        
        useEffect(()=>{
            if(init_value){
                setVal(init_value);
                setError(getError(json_name));
            }
        },[init_value])
    
        function handleChange(e){
            setVal(e.target.value);
            console.log(e.target.value);
            //onChange(e.target.value, json_name, list);
            var new_etas = [...etas];
            new_etas[etas_index].id = e.target.value;
            setEtas(new_etas);
            //setError(getError(json_name));
        }
        return(
            <FormControl className={classes.formControl} error={error!=''}>
                <InputLabel id={json_name}>{label}</InputLabel>
                <Select
                    labelId={json_name}
                    id="demo-simple-select"
                    value={val}
                    onChange={handleChange}
                    disabled={!active}
                    style={{ zIndex: '99999' }}
                >
                    {list && list.map((a, ind, arr) => {
                        return(
                            <MenuItem value={a.id} name={a.name}>{a.name}</MenuItem>
                        )
                    })}
    
                </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        )
    }

    function BuildSelectGeneral({label, list, init_value, externalSettter, extraExternalParams, active=true}){
        const [val, setVal] = useState(preselected_type)

        const classes = useStyles();
        
        useEffect(()=>{
            if(init_value){
                setVal(init_value);
            }
        },[init_value])
    
        function handleChange(e){
            setVal(e.target.value);
            externalSettter(e.target.value, extraExternalParams)
        }
        return(
            <FormControl className={classes.formControl}>
                <InputLabel>{label}</InputLabel>
                <Select
                    id="demo-simple-select"
                    value={val}
                    onChange={handleChange}
                    disabled={!active}
                    style={{ zIndex: '99999' }}
                >
                    {list && list.map((a, ind, arr) => {
                        return(
                            <MenuItem value={a.id} name={a.name}>{a.name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }

    function BuildText({ json_name=null, etas_index=null, label, getError, init_value }) {

        const [val, setVal] = useState('');
        const [error, setError] = useState('');
        useEffect(() => {
            setVal(init_value)
            setError(getError(json_name));
        }, [])
        function handleChange(e) {
            setVal(e.target.value);
            if(etas_index != null){
                var new_etas = etas;
                new_etas[etas_index][json_name] = e.target.value;
                setEtas(new_etas);
            }else if(json_name){
                setInput(e, json_name);
            }
            
            console.log(getError(json_name));
            setError(getError(json_name));
        }
        return (
            <TextField
                error={error !== ''}
                helperText={error}
                value={val}
                size="small"
                id={json_name}
                className={classes.textField}
                label={label}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
            />
        )
    }

    //externalSetter should accept only the new value and nothing else
    function BuildTextGeneral({ init_value, externalSettter, extraExternalParams, label }) {

        const [val, setVal] = useState('');
        const [error, setError] = useState('');
        useEffect(() => {
            setVal(init_value)
        }, [])
        function handleChange(e) {
            setVal(e.target.value);
            externalSettter(e.target.value, extraExternalParams);
        }
        return (
            <TextField
                fullWidth
                error={error !== ''}
                //helperText={error}
                value={val}
                size="small"
                //id={json_name}
                
                label={label}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
            />
        )
    }
    function BuildMultiSelectGeneral({ label, init_value, list, externalSettter, extraExternalParams, active=true}){
        const [selected, setSelected] = useState([]);
        useEffect(()=>{
            if(init_value){     
                setSelected(init_value);
            }
        },[init_value])
    
    
        function onSelect(selectedList, selectedItem) {
            //alert(selectedItem);
            setSelected(selectedList);
            externalSettter(selectedList,extraExternalParams);
            //onChange(selectedList, json_name, etasindex);
            //handleMultiChange(selectedList, json_name);
        }
         
        function onRemove(selectedList, removedItem) {
            setSelected(selectedList);
            externalSettter(selectedList,extraExternalParams);
            //handleMultiChange(selectedList, json_name);
            //onChange(selectedList, json_name, etasindex);
        }
    
    
        return(
            <React.Fragment>
                {label}
                <Multiselect
                options={list}
                selectedValues={selected}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue="name"
                autoComplete="new-password"
                disabled={!active}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
            </React.Fragment>
            
        )
    }

    function BuildCheckbox({ json_name, label, init_value, getError }) {
        const [checked, setChecked] = useState(false);
        useEffect(() => {
            setChecked(init_value)
        }, []);
        function handleChange() {
            var newval = !checked;
            setChecked(newval);
            handleCheckboxChange(newval, json_name)
        }
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        name={json_name}
                        color="primary"
                    />
                }
                label={label}
            />
        )

    }

    function BuildCheckboxGeneral({  label, init_value, externalSettter, extraExternalParams }) {
        const [checked, setChecked] = useState(false);
        useEffect(() => {
            setChecked(init_value)
        }, []);
        function handleChange() {
            var newval = !checked;
            setChecked(newval);
            externalSettter(newval, extraExternalParams)
        }
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                    />
                }
                label={label}
            />
        )

    }
 

    const addEta = () => {
        var new_etas = [...etas];
        new_etas.push(baseEta);
        setEtas(new_etas);
    }

    const addSpecificEta = () => {
        var new_spec_etas = [...specific_etas];
        new_spec_etas.push(baseSpecificEta);
        setSpecificEtas(new_spec_etas);
    }

    function setGeneralPercents(val, externalparams) {
        var new_etas = etas;
        new_etas[externalparams.ind]['percentage'] = val;
        setEtas(new_etas);
    }

    function setSpecificPercents(val, externalparams) {
        var new_spec_etas = specific_etas;
        new_spec_etas[externalparams.ind]['percentage'] = val;
        setSpecificEtas(new_spec_etas);
    }

    function setSpecificRecipients(val, externalparams) {
        var new_spec_etas = specific_etas;
        new_spec_etas[externalparams.ind]['at'] = val;
        setSpecificEtas(new_spec_etas);
    }

    function setGeneralRecipients(val, externalparams) {
        var new_etas = etas;
        new_etas[externalparams.ind]['at'] = val;
        setEtas(new_etas);
    }

    function setGeneralEta(val, externalparams) {
        var new_etas = [...etas];
        new_etas[externalparams.ind]['id'] = val;
        setEtas(new_etas);
    }

    function setSpecificEta(val, externalparams) {
        var new_spec_etas = [...specific_etas];
        new_spec_etas[externalparams.ind]['id'] = val;
        setSpecificEtas(new_spec_etas);
    }

    function setSpecificClient(val, externalparams) {
        var new_spec_etas = [...specific_etas];
        new_spec_etas[externalparams.ind]['clid'] = val;
        setSpecificEtas(new_spec_etas);
    }

    function setSpecificBoost(val, externalparams){
        var new_spec_etas = specific_etas;
        new_spec_etas[externalparams.ind]['boost_coef'] = val;
        setSpecificEtas(new_spec_etas);
    }

    function setGeneralBoost(val, externalparams){
        var new_etas = etas;
        new_etas[externalparams.ind]['boost_coef'] = val;
        setEtas(new_etas);
    }

    function setSpecificTasks(val, externalparams){
        var new_spec_etas = specific_etas;
        new_spec_etas[externalparams.ind]['tasks'] = val;
        setSpecificEtas(new_spec_etas);
    }

    function setGeneralTasks(val, externalparams){
        var new_etas = etas;
        new_etas[externalparams.ind]['tasks'] = val;
        setEtas(new_etas);
    }

    function setETATasks(val, externalparams){
        var new_fields = fields;
        console.log(fields);
        new_fields[externalparams.name]['tasks'] = val;
        setFields(new_fields);
    }

    function addAnotherETA(){
        console.log("In add ETA")
        var new_fields = {...fields};
        console.log(new_fields);
        new_fields[newEtaName] = {}
        new_fields[newEtaName]['tasks'] = [];
        new_fields[newEtaName]['coef'] = 1;
        new_fields[newEtaName]['show'] = true;
        setFields(new_fields);
    }

    function setExtraGeneralEtas(val, externalparams){
        var new_etas = etas;
        new_etas[externalparams.ind]['etas'] = val;
        setEtas(new_etas);
    }

    function setExtraSpecificEtas(val, externalparams){
        var new_spec_etas = specific_etas;
        new_spec_etas[externalparams.ind]['etas'] = val;
        setSpecificEtas(new_spec_etas);
    }

    function setGeneralPM(val, externalparams){
        var new_etas = etas;
        new_etas[region][externalparams.ind]['to_PM'] = val;
        setEtas(new_etas);
    }

    function setSpecificPM(val, externalparams){
        var new_spec_etas = specific_etas;
        new_spec_etas[externalparams.ind]['to_PM'] = val;
        setSpecificEtas(new_spec_etas);
    }


    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <BuildSelectGeneral label='TOP CLIENT' list={top_clients} init_value={top_client} externalSettter={handleSetClient} extraExternalParams={null}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <BuildSelectGeneral label='REGION' list={lists.regions} init_value={region} externalSettter={handleSetRegion} extraExternalParams={null}/>  
                        </CardContent>
                    </Card>
                </Grid>
             
                                                
                 
                <Grid item xs={6}>

                    <Card>
                        <CardContent>
                            <CardHeader title="Base Fields"></CardHeader>
                            <Grid container spacing={2}>
                                {Object.keys(allFields).map((fname)=>{
                                    return(
                                        <Grid item xs={4}>
                                            <Paper className={classes.grey_placeholder}>
                                                <BuildCheckbox json_name={fname + ".show"} label={fname} init_value={fields[fname]?fields[fname].show:false} getError={checkError} />
                                            </Paper>
                                        </Grid>
                                    )
                                })}
                                
                                
                            </Grid>

                        </CardContent>

                    </Card>
                </Grid>

                <Grid item xs={6}>
                    <Card>
                    <CardHeader title="ETA Fields"></CardHeader>
                        <CardContent>
                            
                            <Grid container
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                                spacing={2}
                            >
                                {Object.keys(fields).map((field,indfield)=>{
                                    return (
                                        <>
                                         {fields[field]?.coef &&
                                      <Grid item xs={6}>

                                        <Paper
                                          className={classes.grey_placeholder}
                                        >
                                          <BuildCheckbox
                                            json_name={field + ".show"}
                                            label={field}
                                            init_value={
                                              fields[field]?.show
                                            }
                                            getError={checkError}
                                          />
                                          <BuildText
                                            json_name={field + ".coef"}
                                            label="Coef"
                                            getError={checkError}
                                            helper=""
                                            init_value={
                                              fields[field]?.coef
                                            }
                                          />
                                          <BuildMultiSelectGeneral label="Tasks" init_value={fields[field].tasks} list={taskTypes} externalSettter={setETATasks} extraExternalParams={{name: field}} />
                                                
                                        </Paper>
                                      </Grid>
                                      }
                                      </>
                                    );
                                })}
                                <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    //error={error !== ''}
                                    //helperText={error}
                                    value={newEtaName}
                                    size="small"
                                    label="New ETA Name"
                                    margin="normal"
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {setNewEtaName(e.target.value)}}
                                />
                                    <Button variant="contained" onClick={addAnotherETA}>Add Another</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                  
                        <Paper>
                            <Grid container
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.title}>
                                        ETA Alerts
                                    </Typography>
                                    <Button onClick={addEta}><AddIcon/></Button>
                                </Grid>

                                <Grid item xs={2}>NAME</Grid>
                                <Grid item xs={2}>TASKS</Grid>
                                <Grid item xs={1}>ETAS</Grid>
                                <Grid item xs={1}>%</Grid>
                                <Grid item xs={1}>Boost Coef.</Grid>
                                <Grid item xs={4}>@</Grid>
                                <Grid item xs={1}>to PM</Grid>
                                
                                 
                                    {etas?.map((eta,indeta)=>{
                                        return(
                                            <>
                                            <Grid item xs={2}>
                                                <BuildSelectGeneral label='ETA' list={etatypes} init_value={eta.id} externalSettter={setGeneralEta} extraExternalParams={{ind: indeta}}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Paper>
                                                    <BuildMultiSelectGeneral label="Tasks" init_value={eta.tasks} list={lists.task_types} externalSettter={setGeneralTasks} extraExternalParams={{ind: indeta}} />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildMultiSelectGeneral label="ETAS" init_value={eta.etas} list={etatypes} externalSettter={setExtraGeneralEtas} extraExternalParams={{ind: indeta}} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildTextGeneral label="%"  init_value={eta.percentage} externalSettter={setGeneralPercents} extraExternalParams={{ind: indeta}} label="%" />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildTextGeneral label="Coef. Boost"  init_value={eta.boost_coef} externalSettter={setGeneralBoost} extraExternalParams={{ind: indeta}} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <BuildTextGeneral label="@"  init_value={eta.at} externalSettter={setGeneralRecipients} extraExternalParams={{ind: indeta}} />
                                            
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildCheckboxGeneral label="Alert to PM" init_value={eta.to_PM} externalSettter={setGeneralPM} extraExternalParams={{ind: indeta}}/>
                                            </Grid>
                                            </>
                                        )
                                    })}
                             
                            </Grid>
                        </Paper>
                    
                </Grid>

                <Grid item xs={12}>
                     
                        <Paper>
                            <Grid container
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.title}>
                                        Client-Specific ETA Alerts
                                    </Typography>
                                    <Button onClick={addSpecificEta}><AddIcon/></Button>
                                </Grid>
                                
                                <Grid item xs={2}>Client</Grid>
                                <Grid item xs={1}>NAME</Grid>
                                <Grid item xs={2}>TASKS</Grid>
                                <Grid item xs={1}>ETAS</Grid>
                                <Grid item xs={1}>%</Grid>
                                <Grid item xs={1}>Boost Coef.</Grid>
                                <Grid item xs={2}>@</Grid>
                                <Grid item xs={1}>to PM</Grid>
                                    {specific_etas?.map((eta,indeta)=>{
                                        return(
                                            <>
                                            <Grid item xs={2}>
                                                <BuildSelectGeneral label='Client' list={lists.clients} init_value={eta.clid} externalSettter={setSpecificClient} extraExternalParams={{ind: indeta}}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildSelectGeneral label='ETA' list={etatypes} init_value={eta.id} externalSettter={setSpecificEta} extraExternalParams={{ind: indeta}}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <BuildMultiSelectGeneral label="Tasks" init_value={eta.tasks} list={lists.task_types} externalSettter={setSpecificTasks} extraExternalParams={{ind: indeta}} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildMultiSelectGeneral label="ETAS" init_value={eta.etas} list={etatypes} externalSettter={setExtraSpecificEtas} extraExternalParams={{ind: indeta}} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildTextGeneral label="%"  init_value={eta.percentage} externalSettter={setSpecificPercents} extraExternalParams={{ind: indeta}} label="%" />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildTextGeneral label="Coef. Boost"  init_value={eta.boost_coef} externalSettter={setSpecificBoost} extraExternalParams={{ind: indeta}} />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <BuildTextGeneral label="@"  init_value={eta.at} externalSettter={setSpecificRecipients} extraExternalParams={{ind: indeta}} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <BuildCheckboxGeneral label="Alert to PM" init_value={eta.to_PM} externalSettter={setSpecificPM} extraExternalParams={{ind: indeta}}/>
                                            </Grid>
                                            </>
                                        )
                                    })}
                             
                            </Grid>
                        </Paper>
          
                </Grid>
                <Grid item xl={12}>
                    {showSuccess == true && 
                        <Alert severity="success">Successfully saved changes!</Alert>
                    }
                    
                </Grid>
                <Grid item xl={12}>
                    <Button onClick={handleSubmit} className={classes.button_general}>SAVE!</Button>
                </Grid>
            </Grid>
        </React.Fragment>

    )

}

export { ClientViewModifier }