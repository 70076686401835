import React, {Component} from 'react';
import { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';
import DateFnsUtils from '@date-io/date-fns';
import { FormGroup, Container, FormHelperText } from '@material-ui/core';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import CreatableSelect from 'react-select/creatable';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { components } from 'react-select';

var preselected_type = {
    id: 999999,
    name: 'undefined',
}





  function BuildMultiSelectCreateable({json_name, label, init_value, getError, list, onChange, singleSelect=false, active=true}){
    const [selected, setSelected] = useState(init_value);
    const classes = useStyles();
 


    function onSelect(selectedList, meta) {
        console.log(selectedList);
        setSelected(selectedList);
        onChange(selectedList, json_name);
        //handleMultiChange(selectedList, json_name);
    }

    return(
        <React.Fragment>
            {label}

            <CreatableSelect
            isMulti
            value={selected}
            onChange={onSelect}
            options={list}
            />
        </React.Fragment>
        
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {

    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#eee',
        height: "100%"
    },
    box: {
        padding: '0em',
        cursor: 'pointer',
    },
    wide_popover: {
        display: 'inline-block',
        //minWidth: '200px !important',
        width: '100% !important',
    },
    editable: {
        cursor: 'pointer',
    },
    btn_clear: {
        background: '#AA0000',
        padding: 0,
        fontSize: '11px',
    },
  }));

function BuildSelect({json_name, label, list, init_value, getError, onChange, active=true}){
    const [val, setVal] = useState(preselected_type)
    const [error, setError] = useState('');
    const classes = useStyles();
    useEffect(()=>{
        if(init_value){
            setVal(init_value);
            setError(getError(json_name));
        }
    },[init_value])

    function handleChange(e){
        setVal(e.target.value);
        onChange(e.target.value, json_name, list);
        setError(getError(json_name));
    }
    return(
        <FormControl className={classes.formControl} error={error!=''}>
            <InputLabel id={json_name}>{label}</InputLabel>
            <Select
                labelId={json_name}
                id="demo-simple-select"
                value={val}
                onChange={handleChange}
                disabled={!active}
                style={{ zIndex: '9999' }}
            >
                {list && list.map((a, ind, arr) => {
                    return(
                        <MenuItem value={a.id} name={a.name}>{a.name}</MenuItem>
                    )
                })}

            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}


function BuildSelect2({json_name, label, list, init_value, getError, onChange, active=true, asyncLoadList=null}){
    const [val, setVal] = useState(preselected_type);
    const [error, setError] = useState('');
    const classes = useStyles();
    useEffect(()=>{

        if(init_value){
            setVal(init_value);
            setError(getError(json_name));
        }
    },[init_value])


    function handleChange(newValue){
        if(newValue){
            setVal(newValue);
            onChange(newValue.id, json_name, list);
            setError(getError(json_name));
        }
    }

    function handleInputChange(newText){
        if(asyncLoadList){
            asyncLoadList(newText)
        }
    }

    const clearVal = () => {
        setVal(preselected_type);
        onChange(preselected_type.id, json_name, list);
    }

    return (
        <Autocomplete
            value={val}
            options={list}
            autoHighlight
            disabled={!active}
            style={{ zIndex: '9999' }}
            //fullWidth={true}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
                handleChange(newValue);
              }}
            onInputChange={(event, newValue) => {
                handleInputChange(newValue);
              }}
            renderOption={(option) => (
                <React.Fragment>
                    {option.name}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <React.Fragment>
                    <TextField
                        {...params}
                        error={error!==''}
                        helperText={error}
                        label={label}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        disabled={!active}
                    />
                    <Button onClick={clearVal} className={classes.btn_clear}>clear</Button>
                </React.Fragment>
            )}
        />
        /**
        <FormControl className={classes.formControl} error={error!=''}>
            <InputLabel id={json_name}>{label}</InputLabel>
            <Select
                labelId={json_name}
                id="demo-simple-select"
                value={val}
                onChange={handleChange}
            >
                {list && list.map((a, ind, arr) => {
                    return(
                        <MenuItem value={a.id} name={a.name}>{a.name}</MenuItem>
                    )
                })}

            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
         */
    )
}

function BuildText({json_name, label, init_value, getError, onChange, active=true}){
    const [val, setVal] = useState('');
    const [error, setError] = useState('');
    const classes = useStyles();
    useEffect(()=>{
        setVal(init_value);
    },[])

    useEffect(()=>{
        setVal(init_value);
    },[init_value])

    useEffect(()=>{
        setError(getError(json_name));
    },[val])

    function handleChange(e){
        var stripped = e.target.value.trim()
        setVal(e.target.value);
        onChange(stripped, json_name)
        //setInput(e, json_name);
        setError(getError(json_name));
    }
    return (
        <TextField
            error={error!==''}
            helperText={error}
            value={val}
            size="small"
            id={json_name}
            label={label}
            margin="normal"
            type="text"
            variant="outlined"
            onChange={(e) => handleChange(e)}
            autoComplete="new-password"
            fullWidth
            margin="normal"
            disabled={!active}
            InputLabelProps={{
                shrink: true,
            }}
        />
    )
}


function BuildTextML({json_name, label, init_value, getError, onChange, active=true}){
    const [val, setVal] = useState('');
    const [error, setError] = useState('');
    const classes = useStyles();
    useEffect(()=>{
        setVal(init_value);
    },[])

    useEffect(()=>{
        setVal(init_value);
    },[init_value])

    useEffect(()=>{
        setError(getError(json_name));
    },[val])

    function handleChange(e){
        setVal(e.target.value);
        onChange(e.target.value, json_name)
        //setInput(e, json_name);
        setError(getError(json_name));
    }
    return (
        <TextField
            error={error!==''}
            helperText={error}
            value={val}
            size="normal"
            multiline
            rows={5}
            id={json_name}
            className={classes.textField}
            label={label}
            margin="normal"
            type="text"
            variant="outlined"
            onChange={(e) => handleChange(e)}
            autoComplete="new-password"
            fullWidth
            margin="normal"
            disabled={!active}
          InputLabelProps={{
            shrink: true,
          }}
        />
    )
}

function BuildFloat({json_name, label, init_value, getError, onChange, active=true}){
    const [val, setVal] = useState('');
    const [error, setError] = useState('');
    const classes = useStyles();
    useEffect(()=>{
        setVal(init_value);
    },[])

    useEffect(()=>{
        setVal(init_value);
    },[init_value])

    useEffect(()=>{
        if(val){
            setError(getError(json_name));
            console.log(val);
            onChange(val);
        }
        
    },[val])

    function handleChange(e){
        console.log(e);
        setVal(e.target.value);
        //onChange(e.target.value, json_name)
        //setError(getError(json_name));
    }
    return (
        <TextField
            error={error!==''}
            helperText={error}
            value={val}
            size="small"
            id={json_name}
            className={classes.textField}
            label={label}
            fullWidth
            margin="normal"
            //type="text"
            variant="outlined"
            onChange={(e) => handleChange(e)}
            type="float"
            disabled={!active}
        />
    )
}


function BuildCheckbox({json_name, label, init_value, getError, onChange, active=true}){
    const [checked, setChecked] = useState(false);
    const classes = useStyles();
    useEffect(()=>{
        if(init_value!=null){
            if(init_value == 'True' || init_value == true) setChecked(true)
            if(init_value == 'False' || init_value == false) setChecked(false)
        }
    },[init_value]);
    function handleChange(){
        var newval = !checked;
        setChecked(newval);
        onChange(newval, json_name);
        //handleCheckboxChange(newval, json_name)
    }
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name={json_name}
                    color="primary"
                    disabled={!active}
                />
            }
            label={label}
        />
    )
    
}

function BuildMultiSelect({json_name, label, init_value, getError, list, onChange, singleSelect=false, active=true}){
    const [selected, setSelected] = useState([]);
    const classes = useStyles();
    useEffect(()=>{
        if(init_value){     
            setSelected(init_value);
        }
    },[init_value])


    function onSelect(selectedList, selectedItem) {
        //alert(selectedItem);
        setSelected(selectedList);
        onChange(selectedList, json_name);
        //handleMultiChange(selectedList, json_name);
    }
     
    function onRemove(selectedList, removedItem) {
        setSelected(selectedList, json_name);
        //handleMultiChange(selectedList, json_name);
        onChange(selectedList, json_name);
    }


    return(
        <React.Fragment>
            {label}
            <Multiselect
            options={list}
            selectedValues={selected}
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="name"
            autoComplete="new-password"
            disabled={!active}
            menuPortalTarget={document.body} 
            styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999, position: 'relative' })
              }}
        />
        </React.Fragment>
        
    )
}

function BuildDateTimePicker({init_value, label, json_name, onChange, active=true}){
    const [day, setDay] = React.useState('');
    const [time, setTime] = React.useState('');

    const [selectedDT, setSelectedDT] = React.useState(null);


    useEffect(()=>{
        console.log({init_date_end_start: init_value})
        var d = new Date(init_value);
        setDay(d.getFullYear() + "-" + ('0' + (d.getMonth()+1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2));
        setTime(d.getHours()+":"+(d.getMinutes()<10?d.getMinutes()+'0':d.getMinutes()));
     
    },[])


    useEffect(()=>{

        var DateTime = Date.parse(day + "T" + time);
        console.log({day: day, time: time})
        setSelectedDT(DateTime);
    },[day, time])


    useEffect(()=>{
        if(selectedDT){
            onChange(selectedDT,json_name);
        }
       
    },[selectedDT])

 
    return(
         
<React.Fragment>

            <TextField
                id="date-picker-inline"
                label="Date"
                type="date"
                value={day}
                onChange={(e) => setDay(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={!active}
            />

            <TextField
                id="time-picker-start"
                label="Time"
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
                disabled={!active}
            />
            


</React.Fragment> 
    )
}




function BuildDateTimePickerV2({init_value, label, json_name, onChange, active=true}){
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [day, setDay] = React.useState('');
    const [time, setTime] = React.useState('');
    const [value, onChangeInner] = useState(new Date());
    const classes = useStyles();
    useEffect(()=>{
        console.log({date_change: init_value})
        if(init_value){
            setSelectedDate(init_value);
        }
        onChangeInner(init_value);
        setDay(init_value.getDay+"."+init_value.getMonth()+'.'+init_value.getYear());
    },[init_value])





    useEffect(()=>{
        
        if(day.length && time.length){
            var DateTime = Date.parse(day + "T" + time);
            setSelectedDate(DateTime);
            onChange(DateTime, json_name);
        }
    },[value])


    
    return(
         
        <React.Fragment>
            

            <TextField
                id="date-picker-inline"
                label="Date"
                type="date"
                defaultValue={day}
                onChange={(e) => setDay(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={!active}
            />

            <TextField
                id="time-picker-start"
                label="Time"
                type="time"
                defaultValue={time}
                onChange={(e) => setTime(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
                disabled={!active}
            />

                <DateTimePicker
                    onChange={onChangeInner}
                    value={value}
                />


        </React.Fragment> 
    )
}

function BuildDateStartEndPicker({init_value, label, json_name, onChange, active=true, future=true, start, end, setStart, setEnd}){

    const [selectedDate, setSelectedDate] = React.useState('');


 
    useEffect(()=>{
        console.log({init_date_change: init_value});
        if(init_value){
            setSelectedDate((init_value.getYear()+1900)+ "-" + ("0" + (init_value.getMonth() + 1)).slice(-2) + "-" + ("0" + init_value.getDate()).slice(-2));

        }else{
            var ini = new Date();
            console.log((ini.getYear()+1900)+ "-" + (ini.getMonth()+1) + "-" + ini.getDate());
            setSelectedDate((ini.getYear()+1900)+ "-" + ("0" + (ini.getMonth() + 1)).slice(-2) + "-" + ("0" + ini.getDate()).slice(-2));
        }
    },[init_value])

 

    const handleDateChange = (date) => {
        if(!future){
            console.log({set: Date.parse(date), current: Date.parse(new Date())});
            console.log(new Date(Date.parse(date)));
            console.log(new Date(Date.parse(new Date())));
            if( Date.parse(date) > Date.parse(new Date())){
                alert('Future date');
                onChange(new Date());
                
            }else{
                setSelectedDate(date);
                onChange(date);
            }
        }else{
            setSelectedDate(date);
            onChange(date);
        }
        
    };

    return(

        <React.Fragment>

            <TextField
                id="date-picker-inline"
                label={label + "/ Day"}
                type="date"
                defaultValue={selectedDate}
                value={selectedDate}
                onChange={(e) => handleDateChange(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={!active}
            />

            <TextField
                id="time-picker-start"
                label={label + "/ Start Time"}
                type="time"
                defaultValue={start}
                value={start}
                onChange={(e) => setStart(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
                disabled={!active}
            />

            <TextField
                id="time-picker-end"
                label={label + "/ End Time"}
                type="time"
                defaultValue={end}
                value={end}
                onChange={(e) => setEnd(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
                disabled={!active}
            />


        </React.Fragment>
    )
}


function EditableINP({input, pushUpdate, show}){
    const [edit, setEdit] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const switchEdit = (event) => {
        setEdit(!edit);
        console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    }
    const handleClose = (event) => {
        setEdit(false);
        
        setAnchorEl(null);
    }

    const confirmChange = () => {

        setEdit(false);

        if(pushUpdate){
            pushUpdate();
        }
    }

    return (
            <React.Fragment>
                {edit &&

                    <Popover
                        open={edit}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        onKeyPress={(ev) => {
                            console.log(`Pressed keyCode ${ev.key}`);
                            if (ev.key === 'Enter') {
                                confirmChange();
                                ev.preventDefault();
                            }
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <span className={classes.wide_popover}>{input} <Button onClick={confirmChange}><DoneIcon /></Button></span>

                    </Popover>


                }
             
                <React.Fragment>
                    {show &&
                        <Box onDoubleClick={switchEdit} className={classes.box}>{show}</Box>
                    }
                    {!show &&
                        <Box onDoubleClick={switchEdit} className={classes.box}>blank</Box>
                    }
                </React.Fragment>
                
            </React.Fragment>
    )

}


function  Editor ({init_value, json_name, onChange}) {
   
    const [html, setHtml] = useState(null)
 
    const handleChange = (html) => {
        setHtml(html);
        onChange(html);
    }

    useEffect(()=>{
        if(init_value){
            setHtml(init_value)
        }
    }, [init_value])

    
 
      return (
        <div>
          <ReactQuill 
            theme="snow"
            onChange={handleChange}
            value={html}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={'.app'}
           />
         </div>
       )
    
  }
  
  /* 
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  Editor.modules = {
    toolbar: [
      [ { 'font': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],

      ['link'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  Editor.formats = [
  'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',

    'link', 
  ]

export {BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker, BuildDateStartEndPicker, BuildFloat, EditableINP, BuildTextML, Editor, BuildMultiSelectCreateable}