import React from 'react';
import { useState, useEffect, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Tooltip from '@material-ui/core/Tooltip';

import {URLS} from './URLS';
import { FormGroup, Container, FormHelperText } from '@material-ui/core';
import {AppNavBar} from './AppNavBar'; 
import DateFnsUtils from '@date-io/date-fns';
import {dafault_project, default_project} from './UTILS.js'
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker} from '@material-ui/pickers';

import clock_icon from './assets/images/add_clock.png';
import pen_icon from './assets/images/edit.png';
import info_icon from './assets/images/info.png';
import archive_icon from './assets/images/archive.png';
import unarchive_icon from './assets/images/unarchive.png';
import refresh_icon from './assets/images/refresh.png';
import subroute_icon from './assets/images/sub.png';
import del_icon  from './assets/images/trash.png';
import subscribe_icon from './assets/images/eye.png';
import unsubscribe_icon from './assets/images/eye_cross.png'
import external from './assets/images/external.png'
import log from './assets/images/log.png'
import drive from './assets/images/drive.png'

import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#eee',
        height: "100%"
    },
    button_green: {
        background: '#177F75',
        borderRadius: '5px',
        padding: '0px'
    },
    row: {

    },
    btn_circle: {
        width: 'auto',
        marginRight: '10px',
        fontWeight: 'bolder',
        color: '#333333',
        boxShadow: '3px 3px 3px 0px #888888;',
        height: '40px',
        borderRadius: '20px',
        padding: '0px',
        minWidth: '40px',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    project_paper_high: {
        paddingLeft: '1em',
        paddingRight: '1em',
        border: '2px solid red',
        marginTop: "5px",
    },
    project_paper_medium: {
        paddingLeft: '1em',
        paddingRight: '1em',
        border: '2px solid orange',
        marginTop: "5px",
    },
    project_paper_low: {
        paddingLeft: '1em',
        paddingRight: '1em',
        border: '2px solid yellow',
        marginTop: "5px",
    },
    project_paper_None: {
        paddingLeft: '1em',
        paddingRight: '1em',
        border: '2px solid green',
        marginTop: "5px",
    },
    
}));

class ChartDot extends React.Component {
    constructor(props) {
        super();
        this.dotCanvas = React.createRef();
    }

    componentDidMount() {
        this.updateCanvas();
    }

    componentDidUpdate() {
        this.updateCanvas();
    }

    updateCanvas = () => {

        var percentages = this.props.eta_percentages;
        var topText = 'ETA: ' + this.props.eta;
        var bottomText = 'REV: ' + this.props.rev;

        var ctx = this.dotCanvas.current.getContext('2d');

        ctx.beginPath();
        ctx.arc(50, 50, 50, 0 * Math.PI, 1 * Math.PI);
        ctx.fillStyle = "#00719c";
        ctx.fill();

        ctx.beginPath();
        ctx.arc(50, 50, 50, 1 * Math.PI, 2 * Math.PI);
        ctx.fillStyle = "#001f2b";
        ctx.fill();

        ctx.beginPath();
        ctx.arc(50, 50, 25, 0, 2 * Math.PI);

        var fillColorCenter = ""

        if (percentages < 15) {
            fillColorCenter = "#398564";
        }
        if (percentages >= 15 && percentages < 20) {
            fillColorCenter = "#ffc815";
        }
        if (percentages >= 20) {
            fillColorCenter = "#940000";
        }
        ctx.fillStyle = fillColorCenter;
        ctx.fill();

        ctx.font = "12px Georgia";
        ctx.fillStyle = "#ffffff";
        ctx.fillText(topText, 50 - (ctx.measureText(topText).width / 2), 20);
        ctx.fillText(bottomText, 50 - (ctx.measureText(bottomText).width / 2), 85);
        ctx.fillText(percentages, 50 - (ctx.measureText(percentages).width / 2), 50);
    }

    render() {
        return (
                <div className="text-center">
                    <b>Estimated</b> programming hours:<br/>
                    <div className="chart_dot">
                        <canvas className="chart_dot_canvas" width="100" height="100" ref={this.dotCanvas}></canvas>
                    </div>
                </div>
        )
    }
}

class ChartTable extends React.Component {
    constructor(props) {
        super();
        this.tableCanvas = React.createRef();

    }

    componentDidMount() {
        this.updateCanvas();
    }

    componentDidUpdate() {
        this.updateCanvas();
    }

    updateCanvas = () => {

        var ctx = this.tableCanvas.current.getContext('2d');

        ctx.beginPath();
        ctx.fillStyle = "#39393A";
        ctx.fillRect(0, 0, 100, 33);
        ctx.fill();

        ctx.beginPath();
        ctx.fillStyle = "#63B0CD";
        ctx.fillRect(0, 33, 100, 66);
        ctx.fill();


        ctx.beginPath();
        ctx.fillStyle = "#9B9B93";
        ctx.fillRect(0, 66, 100, 100);
        ctx.fill();

        ctx.beginPath();
        ctx.fillStyle = "#39393A";
        ctx.fillRect(0, 99, 100, 133);
        ctx.fill();

        ctx.font = "12px Georgia";
        ctx.fillStyle = "#ffffff";
        var prText = this.props.prText;
        var chText = this.props.chText;
        var ovText = this.props.ovText;
        var adText = this.props.adText;
        ctx.fillText(prText, 50 - (ctx.measureText(prText).width / 2), 20);
        ctx.fillText(chText, 50 - (ctx.measureText(chText).width / 2), 55);
        ctx.fillText(ovText, 50 - (ctx.measureText(ovText).width / 2), 87);
        ctx.fillText(adText, 50 - (ctx.measureText(adText).width / 2), 120);
    }

    render() {
        return (
                <div className="text-center">
                    <b>Spent</b> programming hours:<br/>**total<br/>
                    <div className="chart_table">
                        <canvas className="chart_table_canvas" width="100" height="135" ref={this.tableCanvas}></canvas>
                    </div>
                </div>
        )
    }
}

class ChartTableDP extends React.Component {
    constructor(props) {
        super();
        this.tableCanvas = React.createRef();

    }

    componentDidMount() {
        this.updateCanvas();
    }

    componentDidUpdate() {
        this.updateCanvas();
    }

    updateCanvas = () => {

        var ctx = this.tableCanvas.current.getContext('2d');

        ctx.beginPath();
        ctx.fillStyle = "#39393A";
        ctx.fillRect(0, 0, 100, 33);
        ctx.fill();

        ctx.beginPath();
        ctx.fillStyle = "#52a167";
        ctx.fillRect(0, 33, 100, 66);
        ctx.fill();

        ctx.beginPath();
        ctx.fillStyle = "#63B0CD";
        ctx.fillRect(0, 66, 100, 99);
        ctx.fill();

        ctx.font = "12px Georgia";
        ctx.fillStyle = "#ffffff";
        var dpText = this.props.dpText;
        var tabText = this.props.tabText;
        var cjdpText = this.props.cjdpText

        ctx.fillText(dpText, 50 - (ctx.measureText(dpText).width / 2), 20);
        ctx.fillText(tabText, 50 - (ctx.measureText(tabText).width / 2), 55);
        ctx.fillText(cjdpText, 50 - (ctx.measureText(cjdpText).width / 2), 90);

    }

    render() {
        return (
                <div className="text-center">
                    <b>Spent</b> programming hours:<br/>**total<br/>
                    <div className="chart_table">
                        <canvas className="chart_table_canvas" width="100" height="99" ref={this.tableCanvas}></canvas>
                    </div>
                </div>
        )
    }
}

function ActionButton({name, onAction, image, helper, variant="standard"}){
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false); 
    function clickDialogButton(){
        setOpenDialog(true)
    }
    function closeDialog(){
        setOpenDialog(false)
    }
    function handleAgree(){
        onAction()
        setOpenDialog(false)
        
    }
    return(
        <React.Fragment>
            <Tooltip title={helper}>
                <Button className={classes.btn_circle} onClick={variant=='standard'?onAction:clickDialogButton}>
                    {!image &&
                        <span title={helper}>{name}</span>
                    }
                    {image && 
                        <img src={image}/>
                    }
                </Button>

            </Tooltip>

            <Dialog
            open={openDialog}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">Please confirm the action</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                The action you're going to execute is permanent, non-revertable and we ask you to confirm it.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleAgree} color="primary">
                Confirm
            </Button>
            <Button onClick={closeDialog} color="primary" autoFocus>
                Forget
            </Button>
            </DialogActions>
            </Dialog>
      </React.Fragment>
    )
}

function ProjectActionButtons({
    project, 
    loadEditForm, 
    handleRefresh, 
    loadSubForm, 
    propagateDelete, 
    loadInfoForm,
    handleArchive,
    handleUnarchive,
    handleOpenExternal,
    handleAddTask,
    handleShowComments,
    onsubscribe,
    onunsubscribe,
    user}){
 

    function clientRedirect(){
        console.log("Redirect");
        var win = window.open(project.client_link, '_blank');
        win.focus();
    }

    var subscribed = false;
    var owned = false;
    
    if(project.subscribers.find(x => x.id == user.id)){
        subscribed = true;
    }
    if(project.owner.id == user.id){
        owned = true;
    }


    const openLog = () => {
        var win = window.open(project.logs_url, '_blank');
        win.focus();
    }

    const openFolder = () => {
        var win = window.open(project.folder_url, '_blank');
        win.focus();
    }

    const buttonMapper = [
        {name: 'AddTask', actionFn: handleAddTask, image: clock_icon, helper: 'Add Task for this project', shown: true},
        {name: 'Edit', actionFn: loadEditForm, image: pen_icon, helper: 'Edit this project', shown: true},
        {name: 'Info', actionFn: loadInfoForm, image: info_icon, helper: 'More Info', shown: false},
        {name: 'Archive', actionFn: handleArchive, image: archive_icon, helper: 'Archive this project', shown: project.archive=="False"},
        {name: 'Unarchive', actionFn: handleUnarchive, image: unarchive_icon, helper: 'Unarchive this project', shown: project.archive=="True"},
        {name: 'Refresh', actionFn: handleRefresh, image: refresh_icon, helper: 'Refresh this project', shown: true},
        {name: 'SubProject', actionFn: loadSubForm, image: subroute_icon, helper: 'Create sub project', shown: project.linked_to=="None"},
        {name: 'Delete', actionFn: propagateDelete, image: del_icon, helper: 'Delete this project', shown: project.tasks_count==0, variant:"dialog"},
        {name: 'Subscribe', actionFn: onsubscribe, image: subscribe_icon, helper: 'Subscribe to this project', shown: !owned && !subscribed},
        {name: "Unsubscribe", actionFn: onunsubscribe, image: unsubscribe_icon, helper: "Unsubscribe from this project", shown: subscribed && !owned},
        //{name: 'SD+', actionFn: null, image: null, helper: 'Show SD+ data', shown: project.sd_plus!="None"},
        {name: 'ClientLink', actionFn: handleOpenExternal, image: external, helper: 'Optional external link for client', shown: true},
        {name: 'Logs', actionFn: openLog, image: log, helper: 'Project Logs', shown: project.logs_url && project.logs_url != ''},
        {name: 'Docs', actionFn: openFolder, image: drive, helper: 'Docs Folder', shown: project.folder_url && project.folder_url != ''},
        {name: 'Notes', actionFn: handleShowComments, image: null, helper: 'Project Notes', shown: true},
    ]

    return (
            <React.Fragment>
                {buttonMapper.map((a)=>{
                    return(
                        
                            <React.Fragment>
                                {a.shown &&
                                    <ActionButton name={a.name} onAction={() => a.actionFn(project)} image={a.image} helper={a.helper} variant={a.variant}/>
                                }
                            </React.Fragment>
 
                    )
                })}
            </React.Fragment>
    )
 

}


const ProjectView = memo(function({prj, lists, user, 
    onDelete, 
    onEdit, 
    onAddTask, 
    style, 
    odDetails, 
    onarchive, 
    onrefresh, 
    onAddSub, 
    onComments,
    onsubscribe,
    onunsubscribe,}){


    const [project, setProject] = useState({...default_project})

    const [client_link, setClientLink] = useState(null);

    const [subs, setSubs] = useState([]);
    const [expand_subs, setExpandSubs] = useState(true)

    const classes = useStyles();


    useEffect(()=>{
        setProject(prj);
        
    },[prj])



    useEffect(()=>{
        setClientLink(URLS.client_external+'?clid='+project.client.id+'&prid='+project.id)
    },[project])

    
    function clientRedirect(){
        var win = window.open(client_link, '_blank');
        win.focus();
    }

    var paper_class = null;

    switch(project.attention.name){
        case 'high':
            paper_class = classes.project_paper_high;
            break;
        case 'medium':
            paper_class = classes.project_paper_medium;
            break;
        case 'low':
            paper_class = classes.project_paper_low;
            break;
        case 'None':
            paper_class = classes.project_paper_None;
            break;
        default:
            paper_class = classes.project_paper_None;
    }



    return (
        <div style={style}>
            <Paper className={paper_class} >
                <Grid container>
                    <Grid item xs={12}>
                        {project.client.name} - {project.project_name}
                         
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item sm={3}>
                                <div className={classes.row}>SN: <b>{project.sn}</b></div>
                                <div className={classes.row}>Mac: <b>{project.maconomy}</b></div>
                                <div className={classes.row}>Office: <b>{project.office.name}</b></div>
                                <div className={classes.row}>Owner: <b>{project.owner.name}</b></div>
                                <div className={classes.row}>Type: <b>{project.type.name}</b></div>
                                <div className={classes.row}>Subscribers: <b>
                                    {project.subscribers && project.subscribers.length !==0 && lists.users &&
                                        <React.Fragment>
                                            {project.subscribers.map((x, i) => {
                                                return lists.users.filter(z => z.id == x.id)[0]?lists.users.filter(z => z.id == x.id)[0].name: ''
                                            }).join(', ')}
                                        </React.Fragment>
                                    }
                                </b></div>
                                <div className={classes.row}>Status: <b><span
                                    className="label label-primary">{project.status.name}</span></b></div>
                                <div className="row prj_orion">Orion: <b>{project.orion}</b></div>
                            </Grid>
                            <Grid item sm={3}>
                                <Grid container>
                                    <Grid item xl={6} xs={6}>
                                        <ChartDot eta_percentages={project.eta_percentages}
                                            eta={project.eta} rev={project.revised_eta} />
                                    </Grid>
                                    <Grid item xl={6} xs={6}>
                                        {user.role != 'DP' &&
                                            <ChartTable prText={"PR: " + project.spent_hours_qdqa}
                                                chText={"CH: " + project.spent_hours_qdqa_changes}
                                                ovText={"OV: " + project.spent_hours_overlay}
                                                adText={"ADM: " + project.spent_hours_admin} />
                                        }
                                        {user.role == 'DP' &&
                                            <ChartTableDP dpText={"DP: " + project.spent_hours_dp}
                                                tabText={"TAB: " + project.spent_hours_tabwork}
                                                cjdpText={'CJ DP: ' + project.spent_hours_cjdp}
                                            />
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item sm={6}>
                                <Grid container>
                                    <Grid item sm={12}>
                                        <ProjectActionButtons
                                            project={project}
                                            loadEditForm={onEdit}
                                            handleRefresh={onrefresh}
                                            loadSubForm={onAddSub}
                                            propagateDelete={onDelete}
                                            loadInfoForm={odDetails}
                                            handleArchive={onarchive}
                                            handleUnarchive={onarchive}
                                            handleAddTask={onAddTask}
                                            handleShowComments={onComments}
                                            handleOpenExternal={clientRedirect}
                                            onsubscribe={onsubscribe}
                                            onunsubscribe={onunsubscribe}
                                            user={user} />
                                    </Grid>
                                    <Grid item sm={12}>
                                        Delivery: {project.days_to_delivery_msg}
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
            
    )
    
})
 

export { ProjectView, ChartDot, ChartTable, ChartTableDP}