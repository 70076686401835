import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';


import {URLS} from './URLS';
import {default_project_filters} from './UTILS'

import {AppNavBar} from './AppNavBar'; 

import {BuildSelect, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker, BuildMultiSelectCreateable} from './Inputs'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#eee',
        height: "100%"
    },
  }));


  function ProjectSearchForm({ lists, onSet, init_filters }) {
    const classes = useStyles();
    var preselected_type = {
        id: 999999,
        name: 'undefined',
    }

    const [filters, setFilters] = useState(default_project_filters)


    var offices = lists.offices;
    var users = lists.users;
    var pms = lists.pms;
    var complexities = lists.complexities;
    var statuses = lists.statuses;
    var attentions = lists.attentions;
    var types = lists.types;
    var general_types = lists.general_types;
    var clients = lists.clients;
    var top_clients = lists.top_clients;
    var tags = lists.tags;

    useEffect(()=>{
        console.log({"INIT_FILTERS": init_filters});
        if(init_filters){
            setFilters(init_filters);
        }
    },[init_filters])

      useEffect(() => {
          offices = lists.offices;
          users = lists.users;
          pms = lists.pms;
          complexities = lists.complexities;
          statuses = lists.statuses;
          attentions = lists.attentions;
          types = lists.types;
          general_types = lists.general_types;
          clients = lists.clients;
          top_clients = lists.top_clients;
          tags = lists.tags;
      }, [lists])

    function setInput(val, json_name){
        var newfilters = filters;
        if (!json_name.includes('.')) {
            newfilters[json_name] = val;
        } else {
            var res = json_name.split('.');
            newfilters[res[0]][res[1]] = val;
        }
        setFilters(newfilters);
    }

    function handleSelectChange(val, json_name, list){
        var elem = list.filter(e => e.id === val)[0]
        var selectedText = elem.name;

        var newfilters = filters;
        if (!json_name.includes('.')) {
            newfilters[json_name].id = val;
            newfilters[json_name].name = selectedText;
        } else {
            var res = json_name.split('.');
            newfilters[res[0]][res[1]].id = val;
            newfilters[res[0]][res[1]].name = selectedText;
        }
        setFilters(newfilters);
 
    }

    function handleCheckboxChange(val, json_name){
        var newfilters = filters;
        if (!json_name.includes('.')) {
            newfilters[json_name] = val;
        } else {
            var res = json_name.split('.');
            newfilters[res[0]][res[1]] = val;
        }
        setFilters(newfilters);
    }

    function handleMultiChange(val, json_name){
        var newfilters = filters;
        if (!json_name.includes('.')) {
            newfilters[json_name] = val;
        } else {
            var res = json_name.split('.');
            newfilters[res[0]][res[1]] = val;
        }
        setFilters(newfilters);
    }

    function handleMultiCreateableChange(val, json_name){
        console.log('new filters multiselect');
        console.log(val);
        var newfilters = filters;
        if (!json_name.includes('.')) {
            if (val){
                newfilters[json_name] = val.map(a=>{return {id:a.value, name: a.label}});
            }
            else{
                newfilters[json_name] = []
            }
        } else {
            var res = json_name.split('.');
            if(val){
                newfilters[res[0]][res[1]] = val.map(a=>{return {id:a.value, name: a.label}});
            }
            else{
                newfilters[res[0]][res[1]] = []
            }
            
        }
        console.log(newfilters)
 
        setFilters(newfilters);
        //handleValidate();
    }

    function handleSubmit(e){
        onSet(filters);
    }
    
    function checkError(json_name){
        return "";
    }



      return (
          <ExpansionPanel>
              <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
              >
                  <Typography className={classes.heading}>Project Filters</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                  <Grid container spacing={8}>
                      <Grid item xl={12} md={12} sm={12}>

                          <Grid container
                              direction="row"
                              justify="space-between"
                              alignItems="stretch"
                              spacing={2}
                          >
                              <Grid item xs={2}>
                                      <BuildText json_name='maconomy' label="Maconomy" getError={checkError} onChange={setInput} init_value={filters['maconomy']} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildText json_name='sn' label="SN" getError={checkError} helper="Usually 6 digits" onChange={setInput} init_value={filters['sn']} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildText json_name='access_pro' label="Access Pro" getError={checkError} onChange={setInput} init_value={filters['access_pro']} />
                              </Grid>

                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name="office" label="Office" init_value={filters.office} getError={checkError} list={offices} onChange={handleMultiChange} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name='client' label='Client' list={clients} init_value={filters.client} getError={checkError} onChange={handleMultiChange} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name='top_client' label='Top Client' list={top_clients} init_value={filters.top_client} getError={checkError} onChange={handleMultiChange} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name="owner" label="Owner" init_value={filters.owner} getError={checkError} list={users} onChange={handleMultiChange} />
                              </Grid>

                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name="type" label="Type" init_value={filters.type} getError={checkError} list={types} onChange={handleMultiChange} />
                              </Grid>

                              <Grid item xs={2}>
                                      <BuildText json_name='project_name' label="Project Name" getError={checkError} onChange={setInput} init_value={filters['project_name']} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildText json_name='sd_plus' label="SD+" getError={checkError} onChange={setInput} init_value={filters['sd_plus']} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name="status" label="Status" init_value={filters.status} getError={checkError} list={statuses} onChange={handleMultiChange} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name='attention' label='Attention' list={attentions} init_value={filters.attention} getError={checkError} onChange={handleMultiChange} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name='general_type' label='General Type' list={general_types} init_value={filters.general_type} getError={checkError} onChange={handleMultiChange} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name="complexity" label="Complexity" init_value={filters.complexity} getError={checkError} list={complexities} onChange={handleMultiChange} />
                              </Grid>

                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name="pm_onshore" label="PM onshore" init_value={filters.pm_onshore} getError={checkError} list={pms} onChange={handleMultiChange} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildMultiSelect json_name="pm_offshore" label="PM offshore" init_value={filters.pm_offshore} getError={checkError} list={pms} onChange={handleMultiChange} />
                              </Grid>
                              <Grid item xs={2}>
                                      <BuildCheckbox json_name="extra_coverage" label="Extra Coverage" init_value={filters.extra_coverage} getError={checkError} onChange={handleCheckboxChange} />
                              </Grid>

                              <Grid item xs={2}>
                                      <BuildCheckbox json_name="orion" label="Orion" init_value={filters.orion} getError={checkError} onChange={handleCheckboxChange} />
                              </Grid>

                              <Grid item xs={2}>
                                      <BuildCheckbox json_name="archived" label="Archived" init_value={filters.archive} getError={checkError} onChange={handleCheckboxChange} />
                              </Grid>

                              <Grid item xs={2}>
                                      <BuildCheckbox json_name="quota_approved" label="Quota" init_value={filters.quota_approved} getError={checkError} onChange={handleCheckboxChange} />
                              </Grid>
                              <Grid item xs={12}>
                                      <BuildMultiSelectCreateable json_name="tags" label="Tags" init_value={filters.tags} getError={checkError} list={tags} onChange={handleMultiCreateableChange} />
                              </Grid>
                          </Grid>
                      </Grid>

                      <Grid item xl={12}>
                          <Button className={classes.button_general} onClick={handleSubmit}>SET</Button>
                      </Grid>
                  </Grid>
              </ExpansionPanelDetails>
          </ExpansionPanel>




      )

}

export {ProjectSearchForm}