import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import $ from 'jquery';
import { URLS } from './URLS';
import { default_project } from './UTILS'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';

import { BuildText } from './Inputs'

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import { Typography, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';

import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { AlertTitle } from '@material-ui/lab';
import LinearProgress from '@material-ui/core/LinearProgress';

var moment = require('moment');


function SDForm({ sdp_info }) {
 const [info, setInfo] = useState(null);
 const [processing, setProcessing] = useState(false);
 useEffect(()=>{
  setInfo(sdp_info);
 },[sdp_info])

 const checkError = () => {
  return ''
 }

 function setInput(val, json_name) {
  var newinfo = info;
  if (!json_name.includes('.')) {
   newinfo[json_name] = val;
  } else {
      var res = json_name.split('.');
      newinfo[res[0]][res[1]] = val;
  }
 
  setInfo(newinfo);
}
function postSDP(){
 setProcessing(true)
 fetch(URLS.aj_post_sdplus,
  {
      method: "POST",
      body: JSON.stringify(info)
  }).then(res => res.json()).then((result) => {
      console.log(result);
      setProcessing(false)
  },

  (error) => {
      console.log(error);
      setProcessing(false)
  })
}
 return(
  <React.Fragment>
  {info!==null && 
   <React.Fragment>
    <BuildText json_name='technician' label="Technician" getError={checkError} onChange={setInput} init_value={info['technician']} active={true} />
    <BuildText json_name='initial hours' label="Initial hours" getError={checkError} onChange={setInput} init_value={info['initial hours']} active={true} />
    {processing && 
    <React.Fragment>
     Pushing info to the SD+ server
     <LinearProgress/>
     </React.Fragment>
    }
    
    <Button onClick={postSDP}>Send</Button>
   </React.Fragment>
  }
  </React.Fragment>
 
 )
}

export {SDForm}