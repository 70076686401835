import React from 'react';
import { useState, useEffect } from 'react';


import {URLS} from './URLS';

import {BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker} from './Inputs'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Card } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#ffffff',
        height: "100%"
    },
    orderableTH: {
        background: '#555555',
        color: '#ffffff',
      },
  }));


function AlertsTab({lists, user}){

    var now = new Date();
    var future = new Date();
    future.setDate(now.getDate() + 1);
    console.log(future);
    const [state, setState] = useState({
     
    });
    var fakestate = {}
    lists.regions.map((reg, ind) => {
     fakestate[reg.id+"emails"] = reg.emails;
     return null
    })


    const classes = useStyles();


   
    const checkError = () => {
        return '';
    }

    function handleSet() {
        console.log(state);

       
        
        fetch(URLS.aj_submit_alert_mails, {method: "POST", body: JSON.stringify(state)})
            .then(response => response.text()).then(result => {
                console.log(result);
            })
            .catch(error => console.error(error));
           
    }

    function handleLoad() {
        fetch(URLS.aj_get_alert_mails, {method: "POST", body: {}})
            .then(response => response.text()).then(result => {
                console.log(JSON.parse(result));
                setState({...JSON.parse(result)});
            })
            .catch(error => console.error(error));
    }

    useEffect(()=>{
        handleLoad();
      },[])


    function setInput(val, json_name){
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
 
        setState(newstate);
 
    }

    function handleSelectChange(val, json_name, list){
        console.log({val: val, json_name: json_name})
        var elem = list.filter(e => e.id === val)[0]
        var selectedText = elem.name;
        console.log(selectedText);
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name].id = val;
            newstate[json_name].name = selectedText;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]].id = val;
            newstate[res[0]][res[1]].name = selectedText;
        }

 
 
        setState(newstate);
    }

    function handleCheckboxChange(val, json_name){
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
        setState(newstate);
    }

    function handleMultiChange(val, json_name){
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
        setState(newstate);
    }



    return (
        <Grid container>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <CardContent>
                     <Grid container spacing={6}>

          {
          Object.entries(state)
          .map( (reg, ind) => {
              console.log(reg)
           return (
            <Grid item sm={12}>
             <Typography variant="h5">{reg[0]}</Typography>
             <BuildMultiSelect json_name={reg[0]} label="emails" init_value={state[reg[0]]} getError={checkError} list={lists.users} onChange={handleMultiChange} />

            </Grid>
           )
          })}
                      


                           
                        </Grid>   
                    </CardContent>
                </Card>
            </Grid>
            
            <Grid item sm={12}>
                <Card>
                    <CardActions>
                        <Button onClick={handleSet} className={classes.button_general}>SET</Button>
                        
                    </CardActions>
                </Card>
            </Grid>

            
            
        </Grid>
    );
}


export {AlertsTab}