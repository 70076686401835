import React from 'react';
import { useState, useEffect, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Tooltip from '@material-ui/core/Tooltip';

import {URLS} from './URLS';
import { FormGroup, Container, FormHelperText } from '@material-ui/core';
import {AppNavBar} from './AppNavBar'; 
import DateFnsUtils from '@date-io/date-fns';
import {dafault_project, default_project} from './UTILS.js'
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker} from '@material-ui/pickers';
import { Typography } from '@material-ui/core';



const ProjectDetails = ({project}) =>{
    console.log(project);
    const displayables = {}
    return(
        <Card>
            <CardContent>
                <Grid conatiner spacing={2}>
                    <Grid item xl={6}>
                        <Typography variant='h5'>Poject Fields</Typography>
                        <Grid container>
                            <Grid item xl={12}>
                                {project.project_name}
                            </Grid>
                            <Grid item xl={4}>
                                {project.maconomy}
                            </Grid>
                            <Grid item xl={4}>
                                {project.sn}
                            </Grid>
                            <Grid item xl={4}>
                                {project.access_pro}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xl={12}>
                                <Typography variant="h6">Spent hours</Typography>
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_admin}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_cjdp}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_data_script}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_data_validation}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_data_visualization}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_dp}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_overlay}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_qa}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_qd}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_qdqa}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_qdqa_changes}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_so}
                            </Grid>
                            <Grid item xl={4}>
                            {project.spent_hours_tabwork}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xl={6}>
                    Comments
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                action buttons
            </CardActions>
        </Card>
    )
}

export {ProjectDetails}