import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import {URLS} from './URLS';
import {default_project} from './UTILS'

import {AppNavBar} from './AppNavBar'; 

import {BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker} from './Inputs'
import { Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import {default_project_filters, available_cols} from './UTILS'

import {ProjectSearchForm} from './ProjectSearchForm';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#ffffff',
        height: "100%"
    },
    orderableTH: {
        background: '#555555',
        color: '#ffffff',
      },
  }));



function ThemeForm({th=null, lists, onNewTheme, onEditedTheme}){

    const [id, setId] = useState(-1);
    const [theme, setTheme] = useState({name:"New Theme"});
    const [variant, setVariant] = useState('SAVE');

    useEffect(()=>{
        if(th){
            setTheme({...th});
            setVariant('Save Changes');
            setId(th.id);
        }else{
            var cols = available_cols.map((a)=>{
                return(
                    {name: a, position: 0}
                )
            })
            setTheme({
                name: '',
                cols: cols,
                filters: default_project_filters
            })
        }
    },[th])

    const onSet = (filters) => {
        setTheme({...theme, filters: {...filters}});
    }
    function setInput(val, json_name){
        var newtheme = {...theme};
        if (!json_name.includes('.')) {
            newtheme[json_name] = val;
        } else {
            var res = json_name.split('.');
            newtheme[res[0]][res[1]] = val;
        }
        setTheme(newtheme);
    }
    const onSubmit = () => {
        if(id!==-1){
            onEditedTheme(id, theme);
        }else{
            onNewTheme(theme);
        }
        
        
    }

    const onColCheck = (selected, colname) => {
        var newcols = [];
        if(selected){
            /** setting new at the back */
            var last_in_line = Math.max(...theme.cols.map(a=>a.position));
            var newcols = theme.cols.map(a=>{
                if(a.name === colname){
                    return {name: a.name, position: last_in_line + 1}
                }else{
                    return a
                }
            })
        }else{
            var pos_to_remove = theme.cols.filter(b=>b.name === colname)[0].position;
            var newcols = theme.cols.map(a=>{
                if(a.position < pos_to_remove){
                    return a
                }else{
                    if(a.position == pos_to_remove){
                        return {...a, position: 0}
                    }else{
                        return {...a, position: a.position - 1}
                    }
                }
            })
        }
        

        setTheme({...theme, cols: [...newcols]});
    }

    return(
        <React.Fragment>
            <BuildText json_name='name' label="Name" getError={()=>{return ''}} onChange={setInput} init_value={theme['name']} />
            <ProjectSearchForm lists={lists} onSet={onSet}/>
            <Table>
                <TableHead>
                    <TableCell>col_name</TableCell>
                    <TableCell>selected</TableCell>
                </TableHead>
                <TableBody>
                    {theme.cols &&
                        <React.Fragment>
                            {theme.cols.map((a) => {
                                return (
                                    <TableRow>
                                        <TableCell>{a.name}</TableCell>
                                        <TableCell>
                                            <BuildCheckbox json_name={a.name} label={a.position} init_value={theme.cols.filter(b => b.name === a.name)[0].selected} getError={() => { return '' }} onChange={onColCheck} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </React.Fragment>
                    }
                </TableBody>
            </Table>
            {theme.name.length != 0 && 
            <Button onClick={onSubmit}>{variant}</Button>
            }
            
        </React.Fragment>
    )
}

function ThemesForm({ user, lists}) {
    const classes = useStyles();

    var preselected_type = {
        id: 999999,
        name: 'undefined',
    }

    const [theme, setTheme] = useState(null);
    const [project_schema, setProjectSchema] = useState({});
    const [project_filters, setProjectFilters] = useState({...default_project_filters});
    const [cols, setCols] = useState([...available_cols]);
    const [themes, setThemes] = useState([]);

    useEffect(()=>{
        fetch(URLS.aj_get_project_schema, {
            method: "POST",
        }).then(res=>res.json()).then(response => {
            setProjectSchema(response.fields);
            
        })
    },[user]);


    
    var offices = lists.offices;
    var users = lists.users;
    var pms = lists.pms;
    var complexities = lists.complexities;
    var statuses = lists.statuses;
    var attentions = lists.attentions;
    var types = lists.types;

    var general_types = lists.general_types;
    var clients = lists.clients;


    
    return (
        <Paper>
            <ThemeForm lists={lists} user={user}/>
        </Paper>

    )

}

export {ThemeForm, ThemesForm}