import React from 'react';
import { useState, useEffect, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Tooltip from '@material-ui/core/Tooltip';

import {URLS} from './URLS';
import { FormGroup, Container, FormHelperText } from '@material-ui/core';
import {AppNavBar} from './AppNavBar'; 
import DateFnsUtils from '@date-io/date-fns';
import {dafault_project, default_project} from './UTILS.js'
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker} from '@material-ui/pickers';
import { Typography } from '@material-ui/core';

import { default_comment } from './UTILS.js';

import { BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker, BuildDateStartEndPicker, BuildFloat, BuildTextML, Editor } from './Inputs'
import Badge from '@material-ui/core/Badge';


import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#eee',
        height: "100%"
    },
    button_green: {
        background: '#177F75',
        borderRadius: '5px',
        padding: '0px'
    },
    button_red: {
        background: '#ff0000',
        borderRadius: '5px',
        padding: '5px'
    },
    row: {

    },
    btn_circle: {
        width: 'auto',
        marginRight: '10px',
        fontWeight: 'bolder',
        color: '#333333',
        boxShadow: '3px 3px 3px 0px #888888;',
        height: '40px',
        borderRadius: '20px',
        padding: '0px',
        minWidth: '40px',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    project_paper_high: {
        paddingLeft: '1em',
        paddingRight: '1em',
        border: '2px solid red',
        marginTop: "5px",
    },
    project_paper_medium: {
        paddingLeft: '1em',
        paddingRight: '1em',
        border: '2px solid orange',
        marginTop: "5px",
    },
    project_paper_low: {
        paddingLeft: '1em',
        paddingRight: '1em',
        border: '2px solid yellow',
        marginTop: "5px",
    },
    project_paper_None: {
        paddingLeft: '1em',
        paddingRight: '1em',
        border: '2px solid green',
        marginTop: "5px",
    },
    with_newlines: {
        whiteSpace: 'pre-line',
        color: '#333333',
        padding: '5px'
    },
    fab: {
        left: "10px",
        top: "10px"
    },
    fab_right: {
        right: "10px",
        top: "10px"
    },
}));


const CommentCard = ({ comment, handleDelete, handleEdit }) => {
    const classes = useStyles();
    const [openDel, setOpenDel] = useState(false);
    
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xl={12}>
                        <Typography>Attention: {comment.attention.name}</Typography>
                    </Grid>
                   
                    <Grid item xl={12}>
                        <div dangerouslySetInnerHTML={{ __html: comment.text }}/>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={() => setOpenDel(true)} color="secondary">Del</Button>
                <Button onClick={handleEdit} className={classes.button_general}>EDIT</Button>
            </CardActions>
            <Dialog
                open={openDel}
                onClose={() => setOpenDel(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle id="alert-dialog-title">Please confirm the action</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The action you're going to execute is permanent, non-revertable and we ask you to confirm it.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleDelete(comment.id); setOpenDel(false) }} color="primary">
                        Confirm
                    </Button>
                    <Button onClick={() => setOpenDel(false)} color="primary" autoFocus>
                        Forget
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )

}


const ProjectComments = ({project, user, lists, onrefresh}) =>{
    
    const [comment, setComment] = useState(null);
    const [prev_comment, setPrevComment] = useState(default_comment);
    
    const [comment_log, setCommentLog] = useState([]);


    const [showForm, setShowForm] = useState(false);

    const classes = useStyles();

    const getComments = () => {
        fetch(URLS.aj_get_comments,{
            method: "POST",
            body:JSON.stringify({
                project_id: project.id
            })
        }).then(res=>res.json()).then(result=>{
            if(result.comment && Object.keys(result.comment).length){
                setComment(result.comment);
                setCommentLog(result.comment_log);
            }else{
                setComment(null);
            }
        })
    }


    useEffect(()=>{

        getComments();
        
    },[project, lists, user])

    const checkError = () => {
        return ''
    }

    function setInput(val, json_name) {
        var newcomment = comment;
        if (!json_name.includes('.')) {
            newcomment[json_name] = val;
        } else {
            var res = json_name.split('.');
            newcomment[res[0]][res[1]] = val;
        }
        setComment(newcomment);
    }

    const handleSelectChange = (val, json_name, list) => {
        console.log({name: json_name, value: val})
        var elem = list.filter(e => e.id === val)[0]
        var selectedText = elem.name;
        var level = elem.level;
        console.log(selectedText);
        var newcomment = comment;
        if (!json_name.includes('.')) {
            newcomment[json_name].id = val;
            newcomment[json_name].name = selectedText;
            newcomment[json_name].level = level;
        } else {
            var res = json_name.split('.');
            newcomment[res[0]][res[1]].id = val;
            newcomment[res[0]][res[1]].name = selectedText;
            newcomment[res[0]][res[1]].level = level;
        }
        console.log(newcomment);
        setComment(newcomment);
    }

    const submitComment = () => {
        var new_coment_log = [...comment_log];
        new_coment_log.push(prev_comment)

        fetch(URLS.aj_post_comment, {
            method: "POST",
            body: JSON.stringify({
                project_id: project.id,
                user_id: user.id,
                comment: comment,
                comment_log: new_coment_log,
            })
        }).then(res=>res.json()).then(response => {
            getComments();
            setShowForm(false);
            onrefresh(project);
        })
    }

    const handleDelete = (comment_id) => {
        fetch(URLS.aj_del_comment,{
            method: "POST",
            body: JSON.stringify({
                project_id: project.id
            })
        }).then(res=>res.json()).then(response=>{
            getComments();
            onrefresh(project);
        })
    }

    const openEdit = (comment) => {
        setComment({...comment});
        setPrevComment({...comment});
        setShowForm(true);
    }

    const setText = (htmlText) => {
        console.log(htmlText);
        var newcomment = comment;
        newcomment['text'] = htmlText;
        setComment(newcomment);
    }

    return(
        <Grid container>
            {! comment &&
                <Grid item xs={12} classes={{ height: '60px' }}>
                    <Fab color="primary" aria-label="add" onClick={() => {setShowForm(true); setComment(default_comment);}} className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </Grid>
            }
            
            {showForm &&
                <Grid item xs={12}>
                 
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>New Comment</Typography>
                                </Grid>
                            
                                <Grid item xs={12} xl={12}>
                                     <BuildSelect2 json_name='attention' label='Attention' list={lists.attentions} init_value={comment['attention']} getError={checkError} onChange={handleSelectChange} /> 
                                </Grid>
                                {false &&
                                    <Grid item xs={12} xl={12}>
                                        <BuildTextML json_name='text' label="Comment text" getError={checkError} onChange={setInput} init_value={comment['text']} />
                                    </Grid>
                                }
                                <Grid item xs={12} xl={12}>
                                    <Editor init_value={comment['text']} onChange={setText}/>
                                </Grid>
                                
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button onClick={submitComment} className={classes.button_general}>SUBMIT</Button>
                            <Button onClick={() => {setShowForm(false); setComment({...prev_comment})}} className={classes.button_red}>Dismiss</Button>
                        </CardActions>
                    </Card>
                </Grid>
            }
            
            <Grid item xs={12}>
                <Paper>
                    {comment && !showForm &&
                        <React.Fragment>
                            <CommentCard comment={comment} handleDelete={handleDelete} handleEdit={()=>openEdit(comment)}></CommentCard>   
                        </React.Fragment>
                    }
                </Paper>
            </Grid>
            <Grid item xs={12}>
                {comment_log.length!==0 && 
                    <React.Fragment>
                        {comment_log.map(a=>{
                            return(
                                <Card>
                                    <CardContent>
                                        {a.date} // {a.user?a.user.name:'undefined'}
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </React.Fragment>
                }
            </Grid>
        </Grid>
        
    )
}

export {ProjectComments}