import React from 'react';
import { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import GridOnIcon from '@material-ui/icons/GridOn';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SaveIcon from '@material-ui/icons/Save';
import { yellow } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    red_alert: {
      background: 'red',
      color: 'white',
    }
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(yellow[500]),
      backgroundColor: yellow[500],
      '&:hover': {
        backgroundColor: yellow[700],
      },
    },
  }))(Button);

function AppNavBar({
  handleAddProject, 
  handleReports, 
  handleAlerts,
  handleAddTask, 
  handleLogOut, 
  handleClientViewModifier, 
  handleClientProjectModifier, 
  db_user, 
  handleSwitchView, 
  handleNewTheme, 
  themes,
  onThemeSelection,
  onThemeSetDefault,
  current_theme,
  unsaved_current_theme,
  update_current_theme,
  onThemeDelete,
  loading_lists
  })
  {

    const classes = useStyles();

    const [openAdminMenu, setOpenAdminMenu] = useState(false);
    const [openThemesMenu, setOpenThemesMenu] = useState(false)
    const [anchorAdmin, setAnchorAdmin] = useState(null);
    const [anchorThemes, setAnchorThemes] = useState(null);
    const [open_del_theme, setOpenDelTheme] = useState(false);
    const [del_theme_index, setDelThemeIndex] = useState(-1);
    const [slectedTheme, setSelectedTheme] = useState(null);
    const { logout } = useAuth0();

    useEffect(()=>{
      themes.forEach((a,ind)=>{
        if(a.default == true){
          setSelectedTheme(ind);
        }
      })
    },[themes])


    const handleOpenClientViewModifier = () => {
      setOpenAdminMenu(false);
      handleClientViewModifier();
    }

    const adminClick = (event) => {
      setAnchorAdmin(event.currentTarget);
      setOpenAdminMenu(true);
    }

    const themesClick = (event) => {
      setAnchorThemes(event.currentTarget);
      setOpenThemesMenu(true);
    }



  return (
    <AppBar position="static">

      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Projects
                </Typography>
        <Typography variant="h6" className={classes.title}>
      {db_user.name} || 
      <Tooltip title="Create or select a theme">
          <Button color="inherit" onClick={themesClick}>{current_theme.name?current_theme.name:'No Theme Selected'} </Button>
        </Tooltip>
  
        {current_theme.name && unsaved_current_theme?<ColorButton onClick={update_current_theme} size="small" startIcon={<SaveIcon />}></ColorButton>:null}
        </Typography>

        {((db_user.role != 'FM' && db_user.extra_role != 'FM' && db_user.role != 'PM' && db_user.extra_role != 'PM') || db_user.admin === true) &&
        <React.Fragment>
        <Button color="inherit" onClick={adminClick} disabled={loading_lists}>Admin</Button>
        
          <Menu
            id="admin-menu"
            anchorEl={anchorAdmin}
            open={openAdminMenu}
            onClose={() => setOpenAdminMenu(false)}
          >
            {db_user.admin === true && <MenuItem onClick={handleOpenClientViewModifier}>Client View Modifier {db_user.admin}</MenuItem>}
            {db_user.admin === true && <MenuItem onClick={handleClientProjectModifier}>Client Project Fields Modifier</MenuItem>}
             <MenuItem onClick={handleReports}>Reports</MenuItem>
            {db_user.admin === true && <MenuItem onClick={handleAlerts}>Alerts</MenuItem>}
          </Menu>
          </React.Fragment>
      }
        

        <Button color="inherit" onClick={handleAddProject} disabled={loading_lists}>Add Project</Button>
        <Button color="inherit" onClick={handleAddTask} disabled={loading_lists}>Add Task</Button>


        <Tooltip title="Switch the view">
          <Button color="inherit" onClick={handleSwitchView}><GridOnIcon /></Button>
        </Tooltip>

        <Menu
          id="themes-menu"
          anchorEl={anchorThemes}
          open={openThemesMenu}
          onClose={() => setOpenThemesMenu(false)}
        >
          <MenuItem onClick={()=>{setOpenThemesMenu(false); handleNewTheme()}}>Create theme</MenuItem>
          {themes && themes.map((a, ind)=>{
            return(
            
                <MenuItem >
                <Button onClick={() => { onThemeSelection(ind); setOpenThemesMenu(false) }} color="inherit">{a.name}</Button>
                <IconButton color="primary" aria-label="del theme" component="span">
                  <DeleteForeverIcon onClick={() => { setOpenDelTheme(true); setDelThemeIndex(ind) }} />
                </IconButton>
                <Radio
                  checked={slectedTheme === ind}
                  onChange={() => { onThemeSetDefault(ind); setOpenThemesMenu(false) }}
                  value={ind}
                  name="radio-theme-select"
                />
                <Button onClick={() => { onThemeSetDefault(ind); setOpenThemesMenu(false) }}>setDefault</Button>
              </MenuItem>
              
            )
          })}
          <Dialog
                  open={open_del_theme}
                  onClose={()=>setOpenDelTheme(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >

                  <DialogTitle id="alert-dialog-title">Please confirm the action</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      The action you're going to execute is permanent, non-revertable and we ask you to confirm it.
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => {onThemeDelete(del_theme_index); setOpenDelTheme(false)}} color="primary">
                      Confirm
                    </Button>
                    <Button onClick={()=>setOpenDelTheme(false)} color="primary" autoFocus>
                      Forget
                    </Button>
                  </DialogActions>
                </Dialog>
           
        </Menu>

        <Tooltip title="Log Out">
          <Button color="inherit" onClick={() => logout({ returnTo: window.location.origin })}><PowerSettingsNewIcon /></Button>
        </Tooltip>


      </Toolbar>
      
    </AppBar>
  )
}


export {AppNavBar}