import React, { useCallback, useRef } from 'react';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import {URLS} from './URLS';
import {AppNavBar} from './AppNavBar'; 
import {ProjectForm} from './ProjectForm';
import {ClientViewModifier} from './ClientViewModifier';
import {ClientProjectFieldsModifier} from './ClientProjectFieldsModifier';
import {ProjectView} from './ProjectView'
import {LightProjectView} from './LightProjectView'
import {ProjectSearchForm} from './ProjectSearchForm'
import {TaskForm} from './TaskForm'
import MuiAlert from '@material-ui/lab/Alert';
import $, { type } from 'jquery';
import { FixedSizeList } from 'react-window';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import {ProjectDetails} from './ProjectDetails';
import {ProjectComments} from './ProjectComments';
import {ReportTab} from './ReportForm';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {ThemeForm} from './ThemesForm';
import {AlertsTab} from './AlertsForm';
//import { Column, Table } from "react-virtualized";
import Draggable from "react-draggable";
import {SDForm} from './SDForm';
import { useAuth0 } from '@auth0/auth0-react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import bright_logo from './assets/images/bright_logo.png'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
        overflowX: 'auto',
        maxHeight: 850,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },

    table: {
        overflowY: 'scroll',
        tableLayout: 'fixed',
        width: '1200px'
    },
    table_body:{
        overflowY: 'scroll',
        overflowX: 'auto',
        tableLayout: 'fixed'
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#eee',
        padding: '10px',
    },
    appBar: {
        position: 'relative',
      },
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      orderableTH: {
        background: '#555555',
        color: '#ffffff',
        borderLeft: '1px solid #999999',
        //padding: '2px',
        
      },
      red_alert: {
        background: 'red',
        color: 'white',
      },
      box: {
          display: 'table-cell',
          width: '5px !important',
          cursor: 'col-resize',
          background: 'transparent'
      }
}));



  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
function FullScreenDialog({open, setClose, content, title}) {
    const classes = useStyles();
    const handleClose = () => {
        setClose();
    };
  
    return (
      <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          {content}
        </Dialog>
      </div>
    );
  }





function AddColumnMenu({available_cols, handleAddCol}) {
    const [av_cols, setAvailable] = useState([available_cols])
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles();
    useEffect(()=>{
        setAvailable(available_cols);
    },[available_cols])

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (x) => {
        setAnchorEl(null);
        handleAddCol(x);
    };



    return (
            <span className="right">

                <Button onClick={handleClick} className={classes.button_general}>add col</Button>
                <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                >
                    {av_cols.map((x,ind) => (
                            <MenuItem onClick={()=>handleClose(x)}>{x}</MenuItem>
                        ) )}

                </Menu>
            </span>
    );

}

const BuildTH = ({ dragOver, dragStart, dragEnter, dragEnd, dragLeave, dragHeader, drop, orderbyme, currentorder, ondelete, data_name, draggable, name, width }) => {

    const classes = useStyles();
    const initialState = {
        mouseX: null,
        mouseY: null,
      };

    const [inner_width, setWidth] = useState(100);
    const [state, setState] = React.useState(initialState);


    const onStart = () => {
        //console.log('whatever')
      };

    const onStop = () => {
        //console.log('whatever')
        dragHeader(data_name, inner_width);
      };

    const dragHandlers = {onStart: onStart, onStop: onStop};


    useEffect(()=>{
        setWidth(width);
        //console.log('setting init width');
    }, [])

    const handleDrag = (e, ui) => {
        setWidth(inner_width + ui.deltaX);
    };

    

    
    
    const handleClick = (event) => {
        event.preventDefault();
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };

    return (
        <React.Fragment>
            <TableCell align="center"
                data-name={data_name}
                className={classes.orderableTH}
                title="Click to order"
                draggable={draggable}
                onDragOver={dragOver}
                onDragStart={dragStart}
                onDragEnter={dragEnter}
                onDragEnd={dragEnd}
                onDragLeave={dragLeave}
                onDropCapture={drop}
                onContextMenu={handleClick}
                width={inner_width}>

                <span>{name}</span>

                {currentorder == data_name &&
                    <span>*.*</span>
                }

            </TableCell>
            <Draggable axis="x" {...dragHandlers} onDrag={handleDrag}>
                <div className={classes.box} ></div>
            </Draggable>
            <Menu
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={()=>ondelete(data_name)}>Delete</MenuItem>
                <MenuItem onClick={()=>orderbyme(data_name)}>Set order</MenuItem>

            </Menu>
        </React.Fragment>

    )
}

function AppContainer(){

    const [initlists, setInitLists] = useState({});
    const [db_user, setDBuser] = useState(null);
    const [openAddProject, setOpenAddProject] = useState(false);
    const [openEditProject, setOpenEditProject] = useState(false);
    const [openCopyProject, setOpenCopyProject] = useState(false);
    const [openSubProject, setOpenSubProject] = useState(false);
    const [openAddTask, setOpenAddTask] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
    const [openReports, setOpenReports] = useState(false);
    const [openAlerts, setOpenAlerts] = useState(false);
    const [openComments, setOpenComments] = useState(false);
    const [openThemeEditor, setOpenThemeEditor] = useState(false);

    const [edited_project, setEditedProject] = useState(null);
    const [copied_project, setCopiedProject] = useState(null);
    const [parent_project, setParentProject] = useState(null);

    const [openClientViewModifier, setOpenClientViewModifier] = useState(false);
    const [openClientProjectFields, setOpenClientProjectFields] = useState(false);
    const [projects, setProjects] = useState([])
    const [project_filters, setProjectFilters] = useState(null)
    const [loading, setLoading] = useState(false);
    const [project_for_tasks, setProjectForTasks] = useState(null);
    const [project_for_details, setProjectForDetails] = useState(null);
    const [project_for_comments, setProjectForComments] = useState(null);

    const [view, setView] = useState("light");

    const [loading_projects, setLoadingProjects] = useState(false);
    const [loading_lists, setLoadingLists] = useState(false);
    
    const [cols_order, setColsOrder] = useState([]);
    const [cols_width, setColsWidth] = useState([]);
    const [themes, setThemes] = useState([]);
    const [current_theme, setDefaultTheme] = useState({});
    const [unsaved_current_theme, setUnsavedCurrentTheme] = useState(false);
    const [default_theme_index, setDefaultThemeIndex] = useState(-1);
    const [available_cols, setAvailableCols] = useState([])
    const ws = useRef(null);
    const [loginError, setLoginError] = useState(false);

    const [lastReportState, setLastReportState] = useState(
        {
            from: new Date(),
            to: new Date(),
            task_type: {id: -1, name: "undef"},
            user: {id: -1, name: "undef"},
            client: {id: -1, name: "undef"},
            top_client: {id: -1, name: "undef"},
            project_number: '',
            maconomy: '',
            study_type: {id: -1, name: 'undef'},
            region: {id: -1, name: 'undef'},
            role: {id: -1, name: 'undef'},
            orion: false,
            status: {id: -1, name: 'undef'},
            by: {id: 1, name: 'User'},
            split_by: [],
            loading_data: false,
            open: false,
            errors: false,
            error_message: 'Please enter correct date-time ranges',
            num_hours: 0,
        }
    );



    const handleUpdateProject = (parsed_project) => {
        console.log(parsed_project);
        //console.log(projects.length);
        var new_projects = projects.map(a=>{
            if(a.id == parsed_project.id){
                return parsed_project
            }else{
                return a
            }
        })
        //console.log({'new_projects': new_projects});

        setProjects([...new_projects]);
    }

    const columns_all = [
        'selected',
        'subs',
        'office',
        'client',
        'project_name',
        'url',
        'sn',
        'maconomy',
        'access_pro',
        'owner',
        'type',
        'status',
        //'controls',
        'delivery',
        'zone',
        'attention',
        'sd_plus',
        'questions',
        'eta_project',
        'eta',
        'eta_tabs',
        'eta_charts',
        'eta_cj',
        'eta_dp',
        'eta_fw',
        'eta_overlay',
        'eta_pm',
        'quota_approved',
        'complexity',
        'revised_eta',
        'eta_percentages',
        //'critical_changes',
        'general_type',
        'extra_coverage',
        'spent_hours_admin',
        'spent_hours_overlay',
        'spent_hours_qa',
        'spent_hours_qd',
        'spent_hours_qdqa',
        'spent_hours_qdqa_changes',

        'spent_hours_dp',
        'spent_hours_data_validation',
        'spent_hours_cjdp',
        'spent_hours_cjpr',
        'spent_hours_data_script',
        'spent_hours_tabwork',
        'spent_hours_data_visualization',
        'spent_hours_charting',

        'last_edit',
        'pm_onshore',
        'pm_offshore',
        'spent_hours_so',
        'fast_add_so',
        'comment',
        'num_country',
        'billed_on',
        'folder_url',
        'logs_url',
        'tags'
    ]

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [sortOrder, setSortorder] = React.useState({cname: '', asc: true});
    const [currentOrder, setsetCurrentOrder] = React.useState({cname: '', asc: false});

    const [sdp_info, setSDPinfo] = React.useState(null);

    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function onLogin(usr){
        console.log({"setting user on login": usr})
        setDBuser({...usr});
    }

    const classes = useStyles();


    useEffect(()=>{
        if(db_user){
            console.log({dbuser: db_user.extra_role})
            setLoadingLists(true);
            getInitList();
            var def_theme = null;
            var def_theme_ind = -1;
            try{
                def_theme = db_user.themes.filter((a)=>{return a.default == true})[0];
                //alert(def_theme);
                db_user.themes.forEach((a,ind)=>{
                    if(a.default == true){
                        def_theme_ind = ind;
                    }
                });
                //alert(def_theme_ind);
            }catch{

            }
            if(def_theme){
                setDefaultThemeIndex(def_theme_ind);
                setProjectFilters({...def_theme.filters, full_search: true, user_id: db_user.id});
                setSortorder({...def_theme.sorting});
                var new_cols_order = def_theme.cols.filter(a=>a.position!==0)
                new_cols_order = new_cols_order.sort(sortCols);
                var new_cols_names = new_cols_order.map(a=>a.name);
                var new_cols_width = new_cols_order.map(a=>{return a.size?a.size:100});
                setColsOrder(new_cols_names);
                setColsWidth(new_cols_width);
                setDefaultTheme(def_theme);
            }else{
                setColsOrder(db_user.view_cols);
                setColsWidth(db_user.view_cols.map(a=>100));
            }
            if(db_user.themes){
                //console.log("setting themes from main app");
                //console.log([...db_user.themes]);
                setThemes([...db_user.themes]);
            }
        }
    },[db_user]);


    useEffect(()=>{
        ws.current = new WebSocket("wss://wtm.bright-research.com/socket");
        ws.current.onopen = () => console.log("ws opened");
        ws.current.onclose = () => console.log("ws closed");
        return () => {
            ws.current.close();
        };
        
        

    },[])

    useEffect(()=>{
        
        //const ws = new WebSocket("ws://localhost:8080/socket");
        ws.current.onmessage = (res) =>  {
            //console.log(JSON.parse(res.data).edited_project);
            if(JSON.parse(res.data).edited_project){
                //alert('got project');
                //console.log(JSON.parse(res.data).edited_project);
                handleUpdateProject(JSON.parse(JSON.parse(res.data).edited_project));
            }else{
                //alert('got tag');
                try{
                    var newinitlists = {...initlists, tags: [...initlists.tags, JSON.parse(res.data).new_tag]}
                    setInitLists(newinitlists);
                }catch{
                    //console.log('something went wrong')
                }
                
            }
            
        }
        
    },[projects])

    useEffect(()=>{
        if(initlists!={} && db_user){
            setLoadingLists(false);
        }
        
    },[initlists]);

    useEffect(()=>{
        setLoadingProjects(true);
        getAllProjects()
        setPage(0);
    },[project_filters])

    useEffect(()=>{
        //console.log(sortOrder);
        //console.log(currentOrder);
        setsetCurrentOrder({cname: '', asc: false});
        forceSort();
    },[sortOrder])

    useEffect(()=>{
        setLoadingProjects(false);
        forceSort();

    },[projects])

    useEffect(()=>{
        //console.log('COLS CHANGED, so modify the availables');
        var col_av = [];
        for(var x=0;x<columns_all.length;x++){
            if(cols_order.indexOf(columns_all[x]) == -1){
                col_av.push(columns_all[x]);
            }
        }
        //console.log({"setting available to": col_av});
        setAvailableCols(col_av);
    },[cols_order]);


    useEffect(()=>{
        try{
            setUnsavedCurrentTheme(true);
            //console.log('updating the current theme col sizes');
            //console.log(cols_order);
            //console.log(project_filters);
            //console.log(default_theme_index);
            var new_themes = [...themes]
            new_themes[default_theme_index].cols = cols_order.map((a,ind)=>{return {name: a, position: ind+1, size: cols_width[ind]}});
            //console.log(new_themes);
            //setThemes(new_themes);
        }catch{}
        
    },[cols_width]);


    function storeReportState(state){
        setLastReportState({...state});
    }

    function getAllProjects() {
        if(!project_filters){
            return 0;
            setLoadingProjects(false);
        }
        //console.log({getting_projects_by: project_filters});
        fetch(URLS.ajax_get_projects,
            {
                method: "POST",
                body: JSON.stringify(project_filters)
            }).then(res => res.json()).then((result) => {
                    //console.log(result);
                    setsetCurrentOrder({cname: '', asc: false});
                    setProjects(result.projects)
                }, (error) => {
                    //alert("Server error while fetching the projects. Try to alter the filters or inform the DEV team.");
                    //setLoadingProjects(false);
                }
            )
    }

    function getInitList(){
        fetch(URLS.aj_get_init_lists,{
            method: "POST",
            body: JSON.stringify(
                {usr_id: db_user.id}
            )
        }).then(res => res.json()).then((response) => {
            console.log({init_lists: response})
            response.so_sub_task_types = response.so_sub_task_types.filter((a) => a.id !== 2)
            setInitLists(response)
        })
    }

    function addNewProject(){
        setOpenAddProject(true);
    }

    function openReport(){
        setOpenReports(true);
    }

    function openAlertsForm(){
        setOpenAlerts(true);
    }

    function handleAddTask(){
        setOpenAddTask(true);
    }

    function handleLogOut(){
        setDBuser(null);
    }

    function handleClientViewModifier(){
        setOpenClientViewModifier(true);
    }

    function handleClientProjectModifier(){
        setOpenClientProjectFields(true)
    }

    function onNewProjectFetch(newProject){
        //setsetCurrentOrder({cname: '', asc: false});
        setProjects([newProject, ...projects]);
        setOpenAddProject(false);
        setOpenCopyProject(false);
    }

    const removeProjectFromList = useCallback((prid) => {
        //setsetCurrentOrder({cname: '', asc: false});
        setProjects(projects => projects.filter(p => p.id !== prid))
    }, [])

    const handleProjectDelete = useCallback((project) => {
        fetch(URLS.aj_delete_project, {
            method: "POST",
            body: JSON.stringify({
                prj_id: project.id,
            })}
            ).then(res=>res.json()).then((result)=>{
                if(result.stat == "OK"){
                    removeProjectFromList(project.id)
                }
            })
    },[])

    const handleProjectEdit = useCallback((project)=>{
        setEditedProject({...project});
    },[])

    const loadCopyForm = useCallback((project) => {
        setCopiedProject({...project});
    },[])

    const handleAddSub = useCallback((project)=>{
        setParentProject({...project});
    },[])

    const handleProjectDetails = useCallback((project) => {
        setProjectForDetails({...project})
    })

    function handleNewFilters(newFilters){
        //console.log({"executing new filters": newFilters})
        setProjectFilters({...newFilters, full_search: true, user_id: db_user.id});
        setUnsavedCurrentTheme(true);
    }

    const handleTaskForProject = useCallback((project) => {
        setProjectForTasks(project);
        setOpenAddTask(true);
    },[])

    const handleEditedProjectFetch = useCallback((project) => {

        var new_projects = projects.map((pr) => {
            if(pr.id == project.id){
                return project
            }else{
                return pr
            }
        })
        //setsetCurrentOrder({cname: '', asc: false});
        setProjects(new_projects);
        setOpenEditProject(false);
        setOpenCopyProject(false);
        setOpenSubProject(false);
    })

    const handleArchiveProject = useCallback((project) => {
        //console.log(project);
      
        var prjId = project.id;
        if (project.archive == "False") {
            var url = URLS.aj_archive_project
        } else {
            var url = URLS.aj_unarchive_project
        }

        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                project_id: prjId,
            })
        }).then(response=>response.json()).then(result => {
            var newpr = JSON.parse(result.project);
            var new_projects = projects.map((a)=>{
                if(a.id === newpr.id){
                    //console.log('match')
                    return newpr
                }else{
                    //console.log('same')
                    return a
                }
            })
            //setsetCurrentOrder({cname: '', asc: false});
            setProjects([...new_projects]);
        })
        
    })

    const onShowSDPLus = useCallback((project) => {
        var url = URLS.aj_get_sdplus;
        //alert(project.sd_plus);
        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                project_id: project.id,
            })
        }).then(response=>response.json()).then(result => {
            setSDPinfo({prj_id: project.id, ...result});
        })

    })

    const subscribetoProject = useCallback((project)=> {
        var prjid = project.id;

        var prjId = project.id;
        var url = URLS.aj_subscribe_to_project;

        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                project_id: prjId,
                user_id: db_user.id,
            })
        }).then(response=>response.json()).then(result => {
            //console.log(result);
        })

    })

    const unSubscribefromProject = useCallback((project)=> {
        var prjid = project.id;
        //console.log("Subscribing to project");
        //console.log(prjid);
        //console.log(db_user.id);

 
      
        var prjId = project.id;
        var url = URLS.aj_unsusbscribe_from_project;

        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                project_id: prjId,
                user_id: db_user.id,
            })
        }).then(response=>response.json()).then(result => {
            //console.log(result);
        })

    })

    const handleRefreshProject = useCallback((project) => {
        //console.log(project);

        var url = URLS.aj_get_project_json;

        fetch(url, {
            method: "POST",
            body: JSON.stringify(
                {
                    prj_id: project.id,
                }
            )
        }).then(res=>res.json()).then(response => {
            //console.log(response);
            var newprj = JSON.parse(response.project);
            var new_projects = projects.map(a=>{
                if(a.id == newprj.id){
                    return newprj
                }else{
                    return a
                }
            })
            //setsetCurrentOrder({cname: '', asc: false});
            setProjects([...new_projects])
        })

        
    })

    const handleProjectComments = useCallback((project)=>{
        setProjectForComments(project);
        setOpenComments(true);
    })

    useEffect(()=>{
        if(edited_project){
            setOpenEditProject(true);
        }
    },[edited_project])

    useEffect(()=>{
        if(copied_project){
            setOpenCopyProject(true);
        }
    },[copied_project])

    useEffect(()=>{
        if(parent_project){
            setOpenSubProject(true);
        }
    },[parent_project])

    useEffect(()=>{
        if(project_for_details){
            setOpenDetails(true);
        }
    },[project_for_details])

    const handleSwitchView = () => {
        var newview = 'default';
        if(view=='default'){
            newview = 'light'
        }else{
            newview = 'default'
        }
        setView(newview);
    }


    const handleHeaderDrag = (col_name, width) => {
        //console.log({'cname': col_name, 'width':width});
        var ind = cols_order.indexOf(col_name);
        //console.log(width);
        var new_widths = [...cols_width];
        new_widths[ind] = width;
        //console.log(new_widths);
        try{
            setColsWidth(new_widths)
        }catch{
            //console.log('cant set');
        }
        
    };

    const onColDragStart = (e) => {
        e.stopPropagation();
        $(e.target).addClass('draggable_start');
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text', $(e.target).attr('data-name'));
    }

    const onColDragEnd = (e) => {
        e.stopPropagation();
        $(e.target).removeClass('draggable_start');
    }

    const onColDragEnter = (e) => {
        e.stopPropagation();
        $(e.target).addClass('droppable_entered'); // this / e.target is the source node.
    }

    const onColDragLeave = (e) => {
        e.stopPropagation();
        $(e.target).removeClass('droppable_entered');
    }

    const onColDragOver = (e) => {
        //return false;
        e.preventDefault();
        e.stopPropagation();
    }

    const onColDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var a = $(e.target).attr('data-name');
        var b = e.dataTransfer.getData('text');
        if(!a){
            a = $(e.target).closest('th').attr('data-name');
        }
        if(a==b){return false;}
        var new_cols = [...cols_order];
        var indb = new_cols.indexOf(b);
        new_cols.splice(indb,1);
        var inda = new_cols.indexOf(a);
        new_cols.splice(inda,0,b);
        //nstate.columns_order[indb] = a;
        setColsOrder(new_cols)
        setUnsavedCurrentTheme(true)

        $(e.target).removeClass('draggable_start');
        $(e.target).removeClass('droppable_entered');
    }

    const onAddColumn = (col) => {
        var new_cols = [...cols_order];
        if(typeof(col) != 'string'){return null};
        new_cols.push(col);
        setColsOrder(new_cols);
        var new_cols_width = new_cols.map(a=>{return a.size?a.size:100});
        setColsWidth(new_cols_width);
        setUnsavedCurrentTheme(true);
    }

    const onDeleteColumn = (col_name) => {
        //console.log(col_name)
        var new_cols = [...cols_order];
        var ind = new_cols.indexOf(col_name);
        new_cols.splice(ind,1);
        setColsOrder(new_cols);
        setUnsavedCurrentTheme(true);
    }

    const header_mapper = {
        'subs':             'SUB',
        'selected':         '*',
        'project_name':     'Name',
        'office':           'Office',
        'sn':               'SN',
        'access_pro':       'Access Pro',
        'client':           'Client',
        'maconomy':         'Maconomy',
        'owner':            'Owner',
        'pm_onshore':       "PM onshore",
        'pm_offshore':      "PM offshore",
        'type':             'Type',
        'status':           'Status',
        'orion':            'Orion',
        'controls':         'CTRL',
        'delivery':         'Delivery',
        'zone':             'Zone',
        'last_edit':        'Last Edit',
        'attention':        "Attention",
        'general_type':     "General Type",
        'sd_plus':          'SD+',
        'questions':        'Questions',
        'eta_project':      'Project ETA',
        'eta':              'ETA Scripting',
        'eta_tabs':         'ETA Tabs',
        'eta_charts':       'ETA Charts',
        'eta_cj':           'ETA CJ',
        'eta_dp':           'ETA DP',
        'eta_fw':           'ETA FW',
        'eta_overlay':      'ETA Overlay',
        'eta_pm':           'ETA PM',
        'quota_approved':   'Quota Approved',
        'complexity':       'Complexity',
        'revised_eta':      'Revised ETA',
        'eta_percentages':  'REV/ETA',
        'extra_coverage':   'Coverage',
        'spent_hours_admin': 'Admin Hours',
        'spent_hours_overlay': "Overlay Hours",
        'spent_hours_qa':   'QA Hours',
        'spent_hours_qd':   'QD Hours',
        'spent_hours_qdqa': 'PROG Hours',
        'spent_hours_so':   'SO hours',
        'spent_hours_qdqa_changes': 'CHANGES hours',
        'spent_hours_dp':   'DP hours', 
        'spent_hours_data_validation': 'Data Validation Hours',
        'spent_hours_cjdp': 'CJ DP Hours',
        'spent_hours_cjpr': 'CJ Progr. Hours',
        'spent_hours_data_script': 'Data Script Hours',
        'spent_hours_tabwork': 'Tabwork Hours',
        'spent_hours_data_visualization': 'Data Visualization Hours',
        'spent_hours_charting': 'Charting Hours',
        'url':              'URL',
        'fast_add_so':      'SO hours ADD',
        'comment':         'Notes Text',
        'num_country': 'Countries',
        'billed_on':    'Billed On',
        'folder_url': "Folder URL",
        'logs_url': "Logs URL",
        'tags': "Project Tags",
    }   

    var shouldSort = true;
    
    const forceSort = () => {

        //console.log(sortOrder);
        //console.log(currentOrder);
        if(currentOrder.cname == sortOrder.cname && currentOrder.asc == sortOrder.asc) return 0;
        var dtype = '';
        try{
            var dtype = typeof projects[0][sortOrder.cname];
        }catch{
            //console.log('no sort type')
        }
        
        var data_name = sortOrder.cname;

        switch (dtype){
            case 'string': {
                //console.log('string order');
                var nprojects = projects.sort((a, b) => {
                    
                    if (a[data_name].toUpperCase() < b[data_name].toUpperCase())
                        if(sortOrder.asc) return -1;
                        else return 1;
                    if (a[data_name].toUpperCase() > b[data_name].toUpperCase())
                        if(sortOrder.asc) return 1;
                        else return -1
                    return 0;
                });
                setsetCurrentOrder({cname: '', asc: false});
                setProjects([...nprojects]);
                break
            }
            case "number": {
                //console.log('number order');
                var nprojects = projects.sort((a, b) => {if(sortOrder.asc){return b[data_name]-a[data_name];} else{return a[data_name]-b[data_name]}});
                setsetCurrentOrder({cname: '', asc: false});
                setProjects([...nprojects]);
                break
            }
            case "object": {
                //console.log('object order');
                switch (data_name){
                    case 'status': {
                        var nprojects = projects.sort((a, b) => {if(sortOrder.asc) {return b[data_name]['order']-a[data_name]['order'];} else {return a[data_name]['order']-b[data_name]['order'];}});
                        setProjects([...nprojects]);
                        break
                    }
                    case 'attention': {
                        //alert('attention')
                        var nprojects = projects.sort((a, b) => {if(sortOrder.asc) {return b[data_name]['level']-a[data_name]['level'];} else {return a[data_name]['level']-b[data_name]['level'];}});
                        setsetCurrentOrder({cname: '', asc: false});
                        setProjects([...nprojects]);
                        break
                    }
                    case 'comment': {
                        var nprojects = projects.sort((a, b) => {
                            var alevel = -1;
                            var blevel = -1;
                            if (a[data_name]){
                                if(a[data_name]['attention']) alevel = a[data_name]['attention']['level'];
                            }
                            if (b[data_name]){
                                if(b[data_name]['attention']) blevel = b[data_name]['attention']['level']
                            }

                            if(sortOrder.asc){return blevel-alevel} else {return alevel - blevel};
                        });
                        setsetCurrentOrder({cname: '', asc: false});
                        setProjects([...nprojects]);
                        break
                    }
                    default: {
                        var nprojects = projects.sort((a, b) => {
                            if (a[data_name]['name'].toUpperCase() < b[data_name]['name'].toUpperCase())
                                if(sortOrder.asc) return -1; else return 1;
                            if (a[data_name]['name'].toUpperCase() > b[data_name]['name'].toUpperCase())
                                if(sortOrder.asc) return 1; else return -1;
                            return 0;
                        });
                        setsetCurrentOrder({cname: '', asc: false});
                        setProjects([...nprojects]);
                    }

                }

            }
            default: {
                //alert(dtype);
                //alert("default");
                //return 0
            }
        }

        setsetCurrentOrder({...sortOrder});
    }


    const orderByColumn = (data_name) => {
        
        var nOrder = {...sortOrder};

        nOrder.cname = data_name;

        if(sortOrder.cname == data_name){
            nOrder.asc = !nOrder.asc;
        }else{
            nOrder.asc = true;
        }

        //console.log(nOrder);
        
        setSortorder(nOrder);
        //console.log(current_theme);
        setDefaultTheme({...current_theme, sorting: nOrder});

        setUnsavedCurrentTheme(true);
    }

    const getItemSize = index => 207;

    const ProjectRow = ({ index, style }) => (
        <ProjectView 
            prj={projects[index]} 
            lists={initlists} 
            user={db_user} 
            onDelete={handleProjectDelete} 
            onEdit={handleProjectEdit}
            onAddSub={handleAddSub}
            onAddTask={handleTaskForProject} 
            odDetails={handleProjectDetails}
            onComments={handleProjectComments}
            key={projects[index].id}
            onarchive={handleArchiveProject}
            onrefresh={handleRefreshProject}
            onsubscribe={subscribetoProject}
            onunsubscribe={unSubscribefromProject}
            style={style}/>
    );

    
    const ListComponent = () => (
        <FixedSizeList
          height={window.innerHeight}
          width='100%'
          itemSize={220}
          itemCount={projects.length}
          className="list-container"
        >
          {ProjectRow}
        </FixedSizeList>
    );

    const pushThemesToDB = () => {
        fetch(URLS.aj_save_user_themes, {
            method: "POST",
            body: JSON.stringify({
                user_id: db_user.id,
                themes: themes,
            })
        }).then(res=>res.json()).then(response=>{
            //console.log(response);
        })
    }

    useEffect(()=>{
        if(db_user){
            pushThemesToDB();
            setUnsavedCurrentTheme(false);
        }
    },[themes])

    const onNewTheme = (theme) => {
        //console.log('setting new theme');
        setThemes([...themes, theme])
        setProjectFilters({...theme.filters, full_search: true, user_id: db_user.id})
        var new_cols_order = theme.cols.filter(a=>a.position!==0)
        new_cols_order = new_cols_order.sort(sortCols);
        var new_cols_names = new_cols_order.map(a=>a.name);
        setColsOrder(new_cols_names);
        setDefaultTheme(theme);
        setOpenThemeEditor(false);
    }

    const onEditedTheme = (id, theme) => {
        var newthemes = [...themes];
        newthemes[id] = theme;
        setThemes(newthemes);
    }

    const sortCols = (a,b) => {
        return a.position - b.position;
    }


    const onThemeSelection = (index) => {
        //console.log("selecting theme");
        //console.log({...themes[index].filters, full_search: true, user_id: db_user.id});
        setProjectFilters({...themes[index].filters, full_search: true, user_id: db_user.id});
        var new_cols_order = themes[index].cols.filter(a=>a.position!==0);
        new_cols_order = new_cols_order.sort(sortCols);
        var new_cols_names = new_cols_order.map(a=>a.name);
        setColsOrder(new_cols_names);
        setSortorder(themes[index].sorting?themes[index].sorting:{cname:'', asc:false});
        var new_cols_width = new_cols_order.map(a=>{return a.size?a.size:100});
        setColsWidth(new_cols_width);
        setDefaultTheme(themes[index]);
        setUnsavedCurrentTheme(false);
        setDefaultThemeIndex(index);
    }

    const onThemeSetDefault = (index) => {
        //console.log(themes[index]);
        var new_themes = [];
        themes.forEach((a,ind)=>{
            if(ind!==index){
                new_themes.push({...a, default: false})
            }else{
                new_themes.push({...a, default: true})
            }
        })
        
        setThemes(new_themes)
    }

    const onThemeDelete = (index) => {
        //console.log("deleting theme");
        var new_themes = [...themes];
        new_themes.splice(index, 1);
        setThemes(new_themes);
    }


    const onUpdateTheme = () => {
        //console.log('updating the current theme');
        //console.log(cols_order);
        //console.log(project_filters);
        //console.log(default_theme_index);
        //console.log(current_theme);
        var new_themes = [...themes]
        try{
            new_themes[default_theme_index].filters = project_filters;
            new_themes[default_theme_index].cols = cols_order.map((a,ind)=>{return {name: a, position: ind+1, size: cols_width[ind]}});
            new_themes[default_theme_index].sorting = sortOrder;
        }catch{
            //console.log(default_theme_index)
        }
        
        //console.log(new_themes);
        setThemes(new_themes);
        setOpenThemeEditor(false);
    }

    const onNewThemeFromCurrent = () => {
        //console.log('creatng new from current theme');
        //console.log(cols_order);
        //console.log(project_filters);
    }

    const [uname, setUname] = useState('');
    const [upass, setUpass] = useState('');

    const logMeIn = () => {
        fetch(URLS.aj_login_ldap,{
            method: "POST",
            body: JSON.stringify(
                {
                    name: uname,
                    password: upass
                }
            )
        }).then(res => res.json()).then((response) => {
            localStorage.setItem('wtm_credentials', JSON.stringify({u: uname, p: upass}));
            if(response.status=='error'){
                setLoginError(true);
            }else{
                onLogin(response);
            }
        })
    }


    return (
        <React.Fragment>
            {!db_user && 
                <Grid container direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}>
                    <Grid item xl={12}>

                        
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="BMR logo"
                                height="140"
                                image={bright_logo}
                                title="BMR LOGO"
                                style={{background: '#333333 !important'}}
                                />
                                <CardContent>
                                    {loginError && 
                                        <Typography gutterBottom variant="h5" component="h2" className={classes.red_alert}>
                                            Invalid credentials!
                                        </Typography>
                                    }
                                    
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Login Here
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        WINDOWS Login
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        //error={error !== ''}
                                        //helperText={error}
                                        value={uname}
                                        size="small"
                                        label="User Name"
                                        margin="normal"
                                        type="text"
                                        variant="outlined"
                                        onChange={(e) => {setUname(e.target.value)}}
                                    /><TextField
                                        fullWidth
                                        //error={error !== ''}
                                        //helperText={error}
                                        value={upass}
                                        size="small"
                                        label="WIN Password"
                                        margin="normal"
                                        type="password"
                                        variant="outlined"
                                        onChange={(e) => {setUpass(e.target.value)}}
                                    />
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                
                            <Button onClick={()=>{logMeIn()}} variant="contained">Log in</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                
            }

            
   
            
            {db_user && 
                <React.Fragment>
                    <AppNavBar
                        handleAddProject={addNewProject}
                        handleReports={openReport}
                        handleAlerts={openAlertsForm}
                        handleAddTask={handleAddTask}
                        handleLogOut={handleLogOut}
                        db_user={db_user}
                        handleClientViewModifier={handleClientViewModifier}
                        handleClientProjectModifier={handleClientProjectModifier}
                        handleSwitchView={handleSwitchView}
                        handleNewTheme={()=>{setOpenThemeEditor(true)}}
                        themes={themes}
                        onThemeSelection={onThemeSelection}
                        onThemeSetDefault={onThemeSetDefault}
                        current_theme={current_theme}
                        unsaved_current_theme={unsaved_current_theme}
                        update_current_theme={onUpdateTheme}
                        onThemeDelete={onThemeDelete}
                        loading_lists={loading_lists} />

                    
                    <FullScreenDialog open={sdp_info!==null} setClose={() => setSDPinfo(null)} content={<SDForm sdp_info={sdp_info}/>} title="SD+ getter/setter" />
                    
                    <ProjectSearchForm user={db_user} lists={{...initlists}} onSet={handleNewFilters} init_filters={project_filters}/>

                    <FullScreenDialog open={openAddTask} setClose={() => setOpenAddTask(false)} content={<TaskForm user={db_user} task_types={initlists.task_types} so_sub_task_types={initlists.so_sub_task_types} prj_id_name={project_for_tasks ? {id: project_for_tasks.id, name: project_for_tasks.project_name} : null} onclose={setProjectForTasks} />} title="Add Task" />

                    <FullScreenDialog open={openAddProject} setClose={() => setOpenAddProject(false)} content={<ProjectForm user={db_user} lists={initlists} onSuccess={onNewProjectFetch} variant="add" />} title="Add Project" />
                    <FullScreenDialog open={openEditProject} setClose={() => setOpenEditProject(false)} content={<ProjectForm user={db_user} lists={initlists} onSuccess={handleEditedProjectFetch} init_project={edited_project} variant="edit" />} title="Edit Project" />
                    <FullScreenDialog open={openCopyProject} setClose={() => setOpenCopyProject(false)} content={<ProjectForm user={db_user} lists={initlists} onSuccess={onNewProjectFetch} init_project={copied_project} variant="copy" />} title="Add Project as Copy" />
                   
                    <FullScreenDialog open={openSubProject} setClose={() => setOpenSubProject(false)} content={<ProjectForm user={db_user} lists={initlists} onSuccess={handleEditedProjectFetch} init_project={parent_project} variant="sub" />} title="Add SUB Project" />
                    <FullScreenDialog open={openDetails} setClose={() => setOpenDetails(false)} content={<ProjectDetails project={project_for_details} />} title="Project Details" />
                    <FullScreenDialog open={openComments} setClose={() => setOpenComments(false)} content={<ProjectComments project={project_for_comments} user={db_user} lists={initlists} onrefresh={handleRefreshProject}/>} title="Project Comments" />
                    <FullScreenDialog open={openClientViewModifier} setClose={() => setOpenClientViewModifier(false)} content={<ClientViewModifier user={db_user} top_clients={initlists.top_clients} lists={initlists}/>} title="Modify Client View" />
                    
                    <FullScreenDialog open={openThemeEditor} setClose={() => setOpenThemeEditor(false)} content={<ThemeForm lists={initlists} onNewTheme={onNewTheme} onEditedTheme={onEditedTheme}/>} title="Create Theme" />

                    <FullScreenDialog open={openClientProjectFields} setClose={() => setOpenClientProjectFields(false)} content={<ClientProjectFieldsModifier user={db_user} top_clients={initlists.top_clients} regions={initlists.regions} />} title="Modify Client Project Required fields" />
                    <FullScreenDialog open={openReports} setClose={() => setOpenReports(false)} content={<ReportTab user={db_user} lists={initlists} initstate={lastReportState} storeReportState={storeReportState}/>} title="Reports" />
                    <FullScreenDialog open={openAlerts} setClose={() => setOpenAlerts(false)} content={<AlertsTab user={db_user} lists={initlists}>ALERTS</AlertsTab>} title="Alerts" />

                    {loading_lists &&
                        <Alert severity="info">Loading initial system lists.</Alert>
                    }
                    {loading_projects &&
                        <Alert severity="info">Loading projects.</Alert>
                    }


                    {projects.length == 0 && 
                        <Toolbar className={classes.red_alert}>
                        No Projects matching the search criteria
                        </Toolbar>
                    }
                    {projects.length != 0 &&
                        <React.Fragment>
                            {view == 'default' &&
                                <React.Fragment>
                                    <ListComponent />
                                </React.Fragment>
                            }
                            {view == 'light' &&
                                <React.Fragment>
                                    <AddColumnMenu available_cols={available_cols}
                                        handleAddCol={onAddColumn} />
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={projects.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                             
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                {[...cols_order].map((cname, ind) => {
                                                    var name = header_mapper[cname]
                                                    return (
                                                        <BuildTH
                                                            dragOver={onColDragOver}
                                                            dragStart={onColDragStart}
                                                            dragEnter={onColDragEnter}
                                                            dragEnd={onColDragEnd}
                                                            dragLeave={onColDragLeave}
                                                            drop={onColDrop}
                                                            orderbyme={orderByColumn}
                                                            currentorder={sortOrder.cname}
                                                            dragHeader={handleHeaderDrag}
                                                            ondelete={onDeleteColumn}
                                                            data_name={cname}
                                                            draggable={true}
                                                            name={name}
                                                            width={cols_width[ind]} />
                                                    )
                                                })}
                                            </TableHead>
                                            <TableBody
                                            className={classes.table_body}
                                            >
                                                {projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((pr) => {
                                                    return (
                                                        <LightProjectView
                                                            init_state={pr}
                                                            cols_order={cols_order}
                                                            lists={initlists}
                                                            user={db_user}
                                                            onDelete={handleProjectDelete}
                                                            onEdit={handleProjectEdit}
                                                            loadCopyForm={loadCopyForm}
                                                            onAddTask={handleTaskForProject}
                                                            onSuccessfullUpdate={handleEditedProjectFetch}
                                                            odDetails={handleProjectDetails}
                                                            onrefresh={handleRefreshProject}
                                                            onarchive={handleArchiveProject}
                                                            onComments={handleProjectComments}
                                                            onAddSub={handleAddSub}
                                                            onShowSDPlus={onShowSDPLus}
                                                            key={pr.id} />
                                                    )
                                                })}
                                            </TableBody>

                                        </Table>
                                  
                                </React.Fragment>
                            }

                        </React.Fragment>
                    }

                </React.Fragment>
            }

        </React.Fragment>
    )
}




export {AppContainer}